import {
  HvBox,
  HvCard,
  HvCardContent,
  HvContainer,
  HvSection,
  HvTypography,
} from "@hitachivantara/uikit-react-core";
import {
  Champion,
  Favorite,
  Fire,
  Time,
} from "@hitachivantara/uikit-react-icons";
import "../styles.css";

const Statistics = () => {
  return (
    <div className="statistics-container">
      <HvCard className="card">
        <HvCardContent>
          <div style={{ paddingTop: "20px" }}>
            <Fire />
          </div>
          <div style={{ paddingTop: "20px" }}>
            <HvTypography variant="label">3 weeks</HvTypography>
            <HvTypography>Current Streak</HvTypography>
          </div>
        </HvCardContent>
      </HvCard>

      <HvCard className="card">
        <HvCardContent>
          <div style={{ paddingTop: "20px" }}>
            <Time />
          </div>
          <div style={{ paddingTop: "20px" }}>
            <HvTypography variant="label">20 hours</HvTypography>
            <HvTypography>Time Spent</HvTypography>
          </div>
        </HvCardContent>
      </HvCard>

      <HvCard className="card">
        <HvCardContent>
          <div style={{ paddingTop: "20px" }}>
            <Champion />
          </div>
          <div style={{ paddingTop: "20px" }}>
            <HvTypography variant="label">8 badges</HvTypography>
            <HvTypography>Labs Visited</HvTypography>
          </div>
        </HvCardContent>
      </HvCard>

      <HvCard className="card">
        <HvCardContent>
          <div style={{ paddingTop: "20px" }}>
            <Favorite />
          </div>
          <div style={{ paddingTop: "20px" }}>
            <HvTypography variant="label">100 stars</HvTypography>
            <HvTypography>You are in the top 10% of users</HvTypography>
          </div>
        </HvCardContent>
      </HvCard>
    </div>
  );
};

export default Statistics;
