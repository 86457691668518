import { theme } from "@hitachivantara/uikit-react-core";
import { css } from "@emotion/css";
import _ from 'lodash';


export const classes = {
    activeButton: css({
        background: "#d4dad9",
        opacity: 1,
        display: "flex !important",
        padding: theme.space.xs,
        '&.HvButton-secondaryGhost:hover': {
            backgroundColor: "#d4dad9",
        }
    }),

    dropdown: css({
        '& .HvDropdown-root': {
            width: '225px'
        },
        '& .HvBaseDropdown-header': {
            border: '1px solid #6C6B6B'
        },
        '& .HvListItem-interactive:not(.HvListItem-disabled):not(.HvListItem-selected):hover': {
            backgroundColor: '#1775E01A !important'
        }
    }),

    root: css({
        '& .HvCard-semanticBar': {
            backgroundColor: theme.colors.sema10
        },
    }),

    button: css({
        cursor: "pointer",
        textAlign: "inherit",
        backgroundColor: theme.colors.atmo1,
        margin: 0,
        border: 0,
        width: "100%",
        "&:focus": {
            outline: "none",
        },
        display: "flex !important"
    }),

    description: css({
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        paddingTop: theme.space.xs,
        paddingLeft: theme.space.xs

    }),
    knowMore: css({
        padding: theme.space.xs,
        cursor: "pointer",
        width:"fit-content",
    }),

    card: css({
        background: theme.colors.atmo1,
        border: '1px solid #CCCED0',
        borderRadius: '0px 0px 6px 6px',
        opacity: 1,
        height: "100%",
        width: "100% !important",
        transition: 'transform 0.3s ease-in-out !important',
        '&:hover': {
            transform: 'scale(1.05) !important',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2) !important'
        },
        cursor: "default",
        '& .HvCard-semanticBar': {
            backgroundColor: theme.colors.sema15
        },
        display: 'flex',
        flexDirection: "column"
    }),

    viewProgressButton: css({
        font: 'normal normal 600 14px / 11px Open Sans',
        letterSpacing: '0px',
        textAlign: 'center',
        color: '#2064B4',
        opacity: 1
    }),

    subtitleLeft: css({
        "& .HvCardHeader-title": {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: "210px",
            color: "#414141",
            fontSize: "18px",
            fontFamily: 'Open Sans ,Arial,Helvetica,sans-serif',
            fontWeight: '600',
            lineHeight: '28px',
            letterSpacing: '0.02em'
        }
    }),

    hvPagination: css({
        '& .HvPagination-pageSizeOptions': {
            display: 'contents',
            position: "inherit"
        },

    }),

    hvButtonPrimary: css({
        '&.HvButton-primaryGhost': {
            color: theme.colors.cat1_180,
            backgroundColor: theme.colors.atmo1,
            border: '1px solid #2064B4',
            fontSize: theme.fontSizes.base,
            fontWeight: theme.fontWeights.normal,
        },
        '&.HvButton-primaryGhost:hover': {
            color: theme.colors.cat1_180,
            backgroundColor: theme.colors.atmo1,
            border: '1px solid #2064B4',
            fontSize: theme.fontSizes.base,
            fontWeight: theme.fontWeights.normal,
        }
    }),
    hvButtonGhost: css({
        '&.HvButton-ghost': {
            border: '#2064B4 1px solid'
        },
        '&.HvButton-ghost:hover': {
            border: '#2064B4 1px solid'
        }
    }),
    hvDialog: css({
        '& .HvDialog-background': {
            backgroundColor: "#00000000"
        },
        '& .HvDialog-paper': {
            boxShadow: "0 2px 12px rgba(65,65,65,0.12)"
        },
        '& .HvDialog-Action-root':{
            maxHeight:"fit-content",
            height:"fit-content"
        },
    }),
    hvActionBar: css({
        '&.HvActionBar-root': {
            paddingTop: theme.space.sm,
            paddingBottom: theme.space.sm,
            paddingLeft: theme.space.sm,
            paddingRight: theme.space.sm
        }
    }),
    hvCardButtonText: css({
        '&.HvTypography-body': {
            color: theme.colors.cat1_180
        }
    }),
    hvPlayVideoButon: css({
        '&.HvButton-ghostSVG svg .color0': {
            fill: theme.colors.cat1_180
        }
    }),
    hvButtonPrimaryScheduler: css({
        '&.HvButton-primary': {
            color: 'var(--toast-black)',
            backgroundColor: '#27d0b287'
        },
        '&.HvButton-primary:hover': {
            color: 'var(--toast-black)',
            backgroundColor: '#27d0b287'
        }
    }),
    hvButtonGhostScheduler: css({
        '&.HvButton-primaryGhost': {
            color: 'var(--toast-black)',
            backgroundColor: '#dd9fb7'
        },
        '&.HvButton-primaryGhost:hover': {
            color: 'var(--toast-black)',
            backgroundColor: '#dd9fb7'
        }
    }),
    controlContainer: css({
        width: "100%",
        maxWidth: 400,
    }),
    labelContainer: css({
        display: "flex",
        alignItems: "flex-start",
    }),
    label: css({
        paddingBottom: "6px",
    }),
    inputContainer: css({
        width: "inherit",
        display: "flex",
        alignItems: "baseline",
        gap: "5px",
        "& > *": {
            width: "100%",
            display: "contents"
        },
        "& > *:first-child": {
            marginLeft: 0,

        },
    }),
    input: css({
        flexGrow: 1,
    }),
    tagLabel: css({
        '& .HvTag-label': {
            fontSize: theme.fontSizes.base,
            fontWeight: theme.fontWeights.normal,
            margin: theme.space.xs,
            cursor: "default",
        }

    }),
    shareButtonGhost: css({
        '&.HvButton-secondaryGhost': {
            minWidth: "60px"
        }
    }),
    hvCardHeader: css({
        '&.HvCardHeader-root': {
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
            borderWidth: '0px !important',
            borderStyle: 'solid !important',
            paddingTop: theme.space.xs,
            paddingLeft: '0',
            paddingRight: '0',
            paddingBottom: '0',
            fontWeight: theme.fontWeights.normal,
            cursor:"default"

        },
        '& .HvCardHeader-title': {
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "block",
            flex: '1',
            overflowY: 'auto',
            paddingTop: theme.space.xs

        }
    }),
    tableRowColor: css({
        "&:nth-of-type(even)": {
            backgroundColor: theme.alpha(theme.colors.atmo3, 0.2),
        },
        "&:nth-of-type(odd)": {
            backgroundColor: theme.alpha(theme.colors.atmo1, 0.4),
        },
    }),

    headerColor : css({
        "&.HvTableHeader-root":{
            backgroundColor:  theme.alpha(theme.colors.sema16, 0.5),
        },
        "&.HvTableHeader-sortable:hover":{
            backgroundColor:theme.alpha(theme.colors.sema16, 0.5),
        },
    
    
    }),

    favoirteColor : css({
        ".color0":{
            fill : "var(--uikit-colors-warning) !important"
        }
    })
}
