import {
  HvContainer,
  HvSection,
  HvStack,
  HvTypography,
} from "@hitachivantara/uikit-react-core";
import Achievements from "./statistics/Achievements";
import UserInfo from "./userInformation/UserInfo";
import Statistics from "./statistics/Statistics";
import LabInteractions from "./statistics/LabInteractions";

const ProfilePage = () => {
  return (
    <HvContainer
      style={{
        // width: "100vw",
        height: "100vh",
        // overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        //     paddingRight: "100px",
      }}
    >
      <HvStack
        style={{
          paddingBottom: "20px",
          paddingTop: "20px",
        }}
      >
        <HvSection
          title={
            <HvTypography variant="title4">
              Welcome Back, Backdoor Admin
            </HvTypography>
          }
        ></HvSection>
      </HvStack>

      <HvStack
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          paddingBottom: "20px",
        }}
      >
        <UserInfo />
        <HvSection style={{ flex: 2 }}>
          <Statistics />
          <LabInteractions />
        </HvSection>
      </HvStack>

      <HvStack
        style={{
          paddingBottom: "20px",
        }}
      >
        <Achievements />
      </HvStack>
    </HvContainer>
  );
};

export default ProfilePage;
