import React, { useEffect, useState, useRef } from 'react';
import LabMasterService from 'services/admin/masters/LabService';
import { HvGrid, HvPanel, HvInput, HvTypography, HvSelectionList, HvListItem, HvButton, HvDropdown, HvSection } from '@hitachivantara/uikit-react-core';
import { DropDownXS, DropLeft, DropRight, DropRightXS } from '@hitachivantara/uikit-react-icons';
import { useDispatch } from 'react-redux';
import { useStyles } from "utils/theme";
import { showBanner } from 'redux/actions/bannerActions';
import _ from 'lodash';
import { buildQueryParams } from 'utils/common';

const ResourcePermission = props => {
    const classes = useStyles();
    const labMasterServiceObj = new LabMasterService();
    const dispatch = useDispatch();
    const [groupUserList, setGroupUserList] = useState([]);
    const [initalGroupUserList, setInitalGroupUserList] = useState([]);
    const [selectedGroupUserList, setSelectedGroupUserList] = useState({
        group: [],
        user: [],
    })
    const [initialSelectedGroupUserList, setInitialSelectedGroupUserList] = useState({
        group: [],
        user: [],
    });
    const {resourceId } = props
    const [labId] = useState(props?.labData?.id);
    const [selectedItem, setSelectedItem] = useState();
    const [selectedItemGroup, setSelectedItemGroup] = useState();
    const [selectedGroupUserItem, setSelectedGroupUserItem] = useState([]);
    const [selectedUserItem, setSelectedUserItem] = useState([]);
    const [selectedGroupAllowed, setSelectedGroupAllowed] = useState([]);
    const [selectedUserAllowed, setSelectedUserAllowed] = useState([]);
    const [group_permissions, setGroupPermission] = useState([]);
    const [user_permissions, setUserPermission] = useState([]);
    const selectedGroupUserListRef = useRef();
    const userGroupRef = useRef();
    let seletectedGroup;
    let selecteduser;
    const [errors, setErrors] = useState('');
    const [excludedSearchValue, setExcludedSearchValue] = useState("");
    const [includedSearchValue, setIncludedSearchValue] = useState("");
    
    const getUniqueUser = (inputArray) => {
        const uniqueObjects = {};
        inputArray.forEach(obj => {
            // Iterate through each key-value pair in the object
            Object.keys(obj).forEach(key => {
                const id = obj[key].id;
                // If the id doesn't exist in uniqueObjects, add the object
                if (!uniqueObjects[id]) {
                    uniqueObjects[id] = obj[key];
                }
            });
        });
        const uniqueArray = Object.values(uniqueObjects);
        return uniqueArray
    }

    useEffect(() => {
        labMasterServiceObj.getAllGroupsWithUsers().then(response => {
          //  setGroupUserList(response.map((item) => { return { id: item.id, label: item.name, children: item.users } }));
            setInitalGroupUserList(response.map((item) => { return { id: item.id, label: item.name, children: item.users } }));
            userGroupRef.current = response.map((item) => { return { id: item.id, label: item.name, children: item.users } })
           
            if (resourceId) {
                let params = {
                    id: resourceId,
                    is_admin: true
                }
                labMasterServiceObj.getResourcePermissionByResourceId(buildQueryParams(params)).then(response => {
                    if (response?.results?.length > 0) {
                        const { group_permissions, user_permissions } = response?.results[0];
                        setGroupUserList(userGroupRef.current?.filter(item => !group_permissions.map(item => item.group_id)?.includes(item.id)));
                        setInitalGroupUserList(userGroupRef.current?.filter(item => !group_permissions.map(item => item.group_id)?.includes(item.id)))
                        
                        setGroupPermission(group_permissions);
                        setUserPermission(user_permissions);
                        if (group_permissions?.length > 0) {
                            seletectedGroup = group_permissions.map(group => {
                                return (
                                    userGroupRef.current?.find((item) => {
                                        if (item?.id === group?.group_id) return item;
                                    })
                                )

                            })
                        }
                        
                        if (user_permissions?.length > 0) {
                            selecteduser = user_permissions.map(user => {
                                return (
                                    userGroupRef.current?.map((item) => {
                                        return (item?.children.find((userItem) => {
                                            if (userItem?.id === user?.user_id) return userItem
                                        }))

                                    })
                                )
                            })?.map(obj =>
                                Object.fromEntries(
                                    Object.entries(obj).filter(([_, value]) => value !== undefined)
                                )
                            ).filter(obj => Object.keys(obj).length !== 0)

                        }
                        let uniqueSelectedUsers = [];
                        if (selecteduser?.length > 0) { uniqueSelectedUsers = getUniqueUser(selecteduser) }

                        setSelectedGroupUserList({
                            ...selectedGroupUserList,
                            group: seletectedGroup?.length > 0 ? seletectedGroup?.map((item) => { return { id: item.id, label: item.label, children: item?.children } }) : [],
                            user: uniqueSelectedUsers?.length > 0 ? uniqueSelectedUsers : [],
                        })
                        setInitialSelectedGroupUserList({
                            ...initialSelectedGroupUserList,
                            group: seletectedGroup?.length > 0 ? seletectedGroup?.map((item) => { return { id: item.id, label: item.label, children: item?.children } }) : [],
                            user: uniqueSelectedUsers?.length > 0 ? uniqueSelectedUsers : [],
                        })

                        selectedGroupUserListRef.current = {
                            group: seletectedGroup?.map((item) => { return { id: item.id, label: item.label, children: item?.children } }),
                            user: uniqueSelectedUsers
                        }

                        userGroupRef.current = userGroupRef.current?.filter(item => !group_permissions.map(item => item.group_id)?.includes(item.id))
                    }
                   
                })
            }


        }).catch(err => {
            console.log(err);
        })

    }, [resourceId]);

    const handleClick = (id) => {
        if (selectedItem === id) {
            setSelectedItem(null);
        } else {
            setSelectedItem(id);
        }
    }

    const handleSelectedClick = (id) => {
        if (selectedItemGroup === id) {
            setSelectedItemGroup(null);
        } else {
            setSelectedItemGroup(id);
        }
    }
    const selectAndMoveGroupUserRight = (item) => {
        const index = selectedGroupUserItem?.findIndex((element) => element?.id === item?.id)
        if (index >= 0) {
            setSelectedGroupUserItem(selectedGroupUserItem.slice(0, index).concat(selectedGroupUserItem.slice(index + 1)));
        } else {
            setSelectedGroupUserItem([...selectedGroupUserItem, item])
        }

    }
    const selectAndMoveUserRight = (item) => {
        const index = selectedUserItem?.findIndex((element) => element?.id === item?.id)
        if (index >= 0) {
            setSelectedUserItem(selectedUserItem.slice(0, index).concat(selectedUserItem.slice(index + 1)))
        } else {
            setSelectedUserItem([...selectedUserItem, item])
        }

    }
    function findMatchingArrays(array1, array2) {
        const matchingArray = [];
        // Iterate over array2
        array2.forEach(obj2 => {
            // Find matching object in array1 by ID
            const matchingObj = array1.find(obj1 => obj1.id === obj2.id);
            if (matchingObj) {
                // If matching object found, push it to newArray
                matchingArray.push(matchingObj);
            }
        });

        return { matchingArray };
    }


    const moveItemToRight = () => {
        if (selectedGroupUserItem.length > 0) {
            setErrors(null)
            const { matchingArray } = findMatchingArrays(selectedGroupUserItem, selectedGroupUserList?.group)
            if (matchingArray.length > 0) {
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Group already exist." },
                        variant: "error"
                    }
                }))
                setSelectedGroupUserItem([]);
                return
            }

        }
        if (selectedUserItem.length > 0) {
            const { matchingArray } = findMatchingArrays(selectedUserItem, selectedGroupUserList?.user)
           
            if (matchingArray.length > 0) {
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "User already exist." },
                        variant: "error"
                    }
                }))
                setSelectedGroupUserItem([]);
                return
            }

        }

        setSelectedGroupUserList({
            ...selectedGroupUserList,
            group: [...selectedGroupUserList.group, ...selectedGroupUserItem],
            user: [...selectedGroupUserList.user, ...selectedUserItem]
        })
        selectedGroupUserListRef.current = {
            group: [...selectedGroupUserList.group, ...selectedGroupUserItem],
            user: [...selectedGroupUserList.user, ...selectedUserItem]
        }
       
        setSelectedGroupUserItem([]);
        setSelectedUserItem([]);
        setGroupUserList(userGroupRef.current?.filter(item => !selectedGroupUserItem.map(item => item.id)?.includes(item.id)));
        setInitalGroupUserList(userGroupRef.current?.filter(item => !selectedGroupUserItem.map(item => item.id)?.includes(item.id)));
        userGroupRef.current = userGroupRef.current?.filter(item => !selectedGroupUserItem.map(item => item.id)?.includes(item.id));
        setExcludedSearchValue("");

    }

    const selectAndMoveUserLeft = (item) => {
        const index = selectedUserAllowed?.findIndex((element) => element?.id === item?.id)
        if (index >= 0) {
            setSelectedUserAllowed(selectedUserAllowed.slice(0, index).concat(selectedUserAllowed.slice(index + 1)))
        } else {
            setSelectedUserAllowed([...selectedUserAllowed, item])
        }

    }

    const selectAndMoveGroupUserLeft = (item) => {
        const index = selectedGroupAllowed?.findIndex((element) => element?.id === item?.id)
        if (index >= 0) {
            setSelectedGroupAllowed(selectedGroupAllowed.slice(0, index).concat(selectedGroupAllowed.slice(index + 1)))
        } else {
            setSelectedGroupAllowed([...selectedGroupAllowed, item])
        }

    }
    const moveItemToLeft = () => {
        setIncludedSearchValue("");
        setSelectedGroupUserList({
            ...selectedGroupUserList,
            group: selectedGroupAllowed.length > 0 ? selectedGroupUserListRef.current.group.filter(item => !selectedGroupAllowed.map(item => item.id).includes(item.id)) : selectedGroupUserList.group,
            user: selectedUserAllowed.length > 0 ? selectedGroupUserListRef.current.user.filter(item => !selectedUserAllowed.map(item => item.id).includes(item.id)) : selectedGroupUserList.user
        })
        if(selectedGroupAllowed.length > 0 ){
            selectedGroupUserListRef.current.group = selectedGroupUserListRef.current.group.filter(item => !selectedGroupAllowed.map(item => item.id).includes(item.id));
        }
        if(selectedUserAllowed.length > 0){
            selectedGroupUserListRef.current.user = selectedGroupUserListRef.current.user.filter(item => !selectedUserAllowed.map(item => item.id).includes(item.id));
        }
        if (selectedGroupUserList?.group?.length === 0 && selectedGroupUserList?.user?.length === 0) {
            dispatch(showBanner({
                payload: {
                    showBanner: true,
                    label: { message: "Included Groups/User list is Empty. Nothing to move." },
                    variant: "error"
                }
            }))
        }else{
            setGroupUserList([...groupUserList, ...selectedGroupAllowed]);
            userGroupRef.current = [...groupUserList, ...selectedGroupAllowed]
            setSelectedGroupAllowed([]);
        }
        
    }
    //excluded
    const renderUserList = () => {
        return (
            groupUserList?.map((item, index) => {
                return (
                    <>
                        <HvSelectionList key={index} multiple={true} >
                            <HvListItem key={index} value={item?.id} onClick={(event) => { event.stopPropagation(); selectAndMoveGroupUserRight(item) }} endAdornment={selectedItem === item?.id ? <DropDownXS onClick={(event) => { 
                                event.stopPropagation(); handleClick(item?.id) }} /> : <DropRightXS onClick={(event) => { event.stopPropagation(); handleClick(item?.id) }} />}>{item?.label} ({item.children?.length})</HvListItem>
                            {
                                selectedItem === item?.id ? item.children.map((childItem, index) => {
                                    return (
                                        <HvListItem key={index} title={childItem?.full_name} onClick={() => { selectAndMoveUserRight(childItem) }} value={childItem?.id} style={{ height: "45px" }}>{<HvTypography variant='normalText'>{childItem?.full_name}<br />{childItem?.email}</HvTypography>}</HvListItem>
                                    )
                                }) : <></>
                            }
                        </HvSelectionList>
                        <br />

                    </>

                )
            })
        )
    }

//included Group
    const renderSelectedGroupList = () => {
        if (selectedGroupUserList?.group?.length > 0) {
            return (
                selectedGroupUserList?.group.map((item, index) => {
                    return (
                        <>
                            <HvSelectionList key={index} name='Excluded groups/users' multiple={true} orientation='vertical'>
                                <HvListItem value={item?.id} onClick={() => { selectAndMoveGroupUserLeft(item) }} endAdornment={selectedItemGroup === item?.id ? <DropDownXS onClick={() => { handleSelectedClick(item?.id) }} /> : <DropRightXS onClick={() => { handleSelectedClick(item?.id) }} />}>{item?.label} ({item.children?.length})</HvListItem>
                                {
                                    selectedItemGroup === item?.id ? item.children.map((childItem, index) => {
                                        return (
                                            <HvListItem key={index} title='test' value={childItem?.id} style={{ height: "45px" }}>{<HvTypography variant='normalText'>{childItem?.full_name}<br />{childItem?.email}</HvTypography>}</HvListItem>

                                        )
                                    }) : <></>
                                }
                            </HvSelectionList>
                            <br />
                        </>

                    )
                })
            )
        } else {
            return (<></>)
        }

    }
//included user
    const renderSelectedUserList = () => {
        return (
            selectedGroupUserList?.user.map((item, index) => {
                return (
                    <><HvSelectionList multiple><HvListItem key={index} id={item?.id} value={item?.id} style={{ height: "45px" }} onClick={() => { selectAndMoveUserLeft(item) }}>{<HvTypography variant='normalText'>{item?.full_name}<br />{item?.email}</HvTypography>}</HvListItem></HvSelectionList> <br /></>

                )
            })
        )
    }


    function search(query) {
        if (query.trim() === '') {
            setSelectedGroupUserItem([]);
            setSelectedUserItem([]);
            return initalGroupUserList;
        }
        return userGroupRef.current.map(item => ({
            ...item,
            children: item.children.filter(child =>
                (child.full_name && child.full_name.toLowerCase().includes(query.toLowerCase())) ||
                (child.email && child.email.toLowerCase().includes(query.toLowerCase()))
            )
        })).filter(item => item.children.length > 0);
       
    }

    const filterGroupUserList = (e) => {
        setExcludedSearchValue(e?.target.value);
        const filteredGroupList = search(e?.target.value);
        if (filterGroupUserList?.length > 0) {
            setGroupUserList(filteredGroupList);
        } else {
            setGroupUserList(initalGroupUserList);
        }
    }

    const searchSelected = (query) => {
        if (query.trim() === '') {
            return selectedGroupUserList
        }
        const filteredGroup = selectedGroupUserList?.group?.map(item => ({
            ...item,
            children: item.children.filter(child =>
                (child.full_name && child.full_name.toLowerCase().includes(query.toLowerCase())) ||
                (child.email && child.email.toLowerCase().includes(query.toLowerCase()))
            )
        })).filter(item => item.children.length > 0)

        const filterSelectedUser = selectedGroupUserList.user.filter((item) => {
            if (item?.full_name?.toLowerCase().includes(query.toLowerCase()) || item?.email?.toLowerCase().includes(query.toLowerCase())) {
                return (
                    item
                )
            }
        })
        if (filterSelectedUser?.length > 0 || filteredGroup?.length > 0) {
            return {
                group: filteredGroup,
                user: filterSelectedUser
            }
        } else {
            return {
                group: [],
                user: []
            }
        }

    }

    const filterSelectedGroupUserList = (e) => {
        setIncludedSearchValue(e?.target.value)
        if (!_.isEmpty(e?.target.value)) {
            const filteredGroupUserList = searchSelected(e?.target.value)
            setSelectedGroupUserList(filteredGroupUserList)
        } else {
            setSelectedGroupUserList(selectedGroupUserListRef.current)
        }
    }

    const handlePermissionSave = () => {
        const payload = {
            resource: resourceId,
            group: selectedGroupUserList.group?.map((element) => { return element?.id }),
            user: selectedGroupUserList.user?.map((element) => { return element?.id })
        }
        if (selectedGroupUserList?.user?.length === 0 && selectedGroupUserList?.group?.length === 0) {

            const userId = user_permissions[0]?.id;
            const groupId = group_permissions[0]?.id;

            if (userId) {
                labMasterServiceObj.deleteResourceUserPermission(userId).then(() => {
                    props.setNextStep(false);
                }).catch(error => {
                    console.log(error)
                })
            }
            if (groupId) {
                labMasterServiceObj.deleteResourceGroupPermission(groupId).then(() => {
                    props.setNextStep(false);
                }).catch(error => {
                    console.log(error)
                })

            }
            props.setNextStep(false);
            setErrors("Nothing to move. Please select Groups/Users to exclude.")
            return false;
        } else {
            if (selectedGroupUserList.user && selectedGroupUserList.user?.length > 0 && user_permissions.length === 0 && user_permissions) {
                labMasterServiceObj.createResourceUserPermission(payload).then(response => {
                    if (response) {
                        props.setNextStep(false);
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
            else if (user_permissions && user_permissions.length > 0 && (selectedGroupUserList.user.length > 0 || selectedGroupUserList.user.length === 0)) {
                const userId = user_permissions[0].id;
                if (selectedGroupUserList.user.length === 0) {
                    labMasterServiceObj.deleteResourceUserPermission(userId).then(() => {
                        props.setNextStep(false);
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    labMasterServiceObj.updateResourceUserPermission(payload, userId).then(response => {
                        if (response) {
                            props.setNextStep(false);
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }


            }
            if (group_permissions && group_permissions.length === 0 && selectedGroupUserList.group && selectedGroupUserList.group?.length > 0) {
                const payload = {
                    lab: labId,
                    group: selectedGroupUserList.group.map((element) => { return element?.id }),
                    resource: resourceId
                }
                labMasterServiceObj.createResourceGroupPermission(payload).then(response => {
                    if (response) {
                        props.setNextStep(false);
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
            else
                if (group_permissions && group_permissions.length === 1 && selectedGroupUserList.group?.length === 0) {
                    const groupId = group_permissions[0].id;
                    labMasterServiceObj.deleteResourceGroupPermission(groupId).then(() => {
                        props.setNextStep(false);
                    }).catch(error => {
                        console.log(error)
                    })
                } else {
                    if (group_permissions && group_permissions.length > 0 && selectedGroupUserList.group?.length > 0) {
                        const groupId = group_permissions[0].id;
                        const payload = {
                            lab: labId,
                            group: selectedGroupUserList.group.map((element) => { return element?.id }),
                            resource: resourceId
                        }
                        labMasterServiceObj.updateResourceGroupPermission(payload, groupId).then(response => {
                            if (response) {
                                props.setNextStep(false);
                            }
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                } if (group_permissions && group_permissions.length > 0 && selectedGroupUserList.group?.length === 0) {
                    const groupId = group_permissions[0].id;
                    labMasterServiceObj.deleteResourceGroupPermission(groupId).then(() => {
                        props.setNextStep(false);
                    }).catch(error => {
                        console.log(error)
                    })
                }

        }

    }
    return (
        <div>
            <form onSubmit={(event) => {
                event.preventDefault();
                handlePermissionSave()
            }}>
                <HvGrid container style={{ paddingBottom: "20px", justifyContent: "center" }}>
                    <HvGrid item xs={4} md={4} xl={4} sm={3}>
                        <HvTypography variant='sTitle' style={{ padding: '20px' }}>Excluded Groups/Users</HvTypography>
                        <HvPanel style={{ height: "400px" }}>
                            <HvInput aria-label='Search' placeholder='Search' type='Search' onChange={filterGroupUserList}  value={excludedSearchValue} />

                            {
                                groupUserList && renderUserList()
                            }

                        </HvPanel>
                    </HvGrid>
                    <HvGrid item xs={1} md={1} xl={1} sm={1} alignContent={"center"}>
                        <HvButton variant='primaryGhost' onClick={moveItemToRight} ><DropRight /></HvButton>
                        <HvButton variant='primaryGhost' onClick={moveItemToLeft}><DropLeft /></HvButton>
                    </HvGrid>
                    <HvGrid item xs={4} md={4} xl={4} sm={3} style={{ paddingBottom: "20px", justifyContent: "center" }}>
                        <HvTypography variant='sTitle' style={{ padding: '20px' }}>Included Groups/Users</HvTypography>
                        <HvPanel style={{ height: "400px" }}>
                            <HvInput aria-label='Search Selected' placeholder='Search' type='Search' onChange={filterSelectedGroupUserList}  value={includedSearchValue}/>

                            {
                                selectedGroupUserList && renderSelectedGroupList()

                            }
                            {

                                selectedGroupUserList && renderSelectedUserList()
                            }

                        </HvPanel>
                        {(errors && errors !== undefined) ? <p className={classes.errorLabel}>{errors}</p> : null}
                    </HvGrid>
                </HvGrid>
                <HvSection style={{ position: "static" }}>
                    <HvGrid container>
                        <HvGrid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                            <HvButton variant='primarySubtle' type='submit' > Save </HvButton>
                        </HvGrid>
                    </HvGrid>
                </HvSection>
            </form>


        </div>
    );
};

ResourcePermission.propTypes = {

};

export default ResourcePermission;