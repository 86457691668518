import React, { useEffect, useRef } from "react";

const AppStreamEmbed = ({ sessionURL, openedInSeparateTab }) => {
  const containerRef = useRef(null);
  const appstreamEmbedRef = useRef(null); // Store AppStream instance

  useEffect(() => {
    if (window.AppStream && containerRef.current) {
      const appstreamOptions = {
        sessionURL: sessionURL,
        userInterfaceConfig: {
          [window.AppStream.Embed.Options.HIDDEN_ELEMENTS]: [window?.AppStream.Embed.Elements.END_SESSION_BUTTON]
        },
      };

      try {
        // Initialize AppStream embed instance
        appstreamEmbedRef.current = new window.AppStream.Embed(
          containerRef.current.id,
          appstreamOptions
        );
      } catch (error) {
        console.error("Failed to initialize AppStream embed:", error);
      }
    }

    return () => {
      try {
        if (appstreamEmbedRef.current && typeof appstreamEmbedRef.current?.disconnect === "function") {
          appstreamEmbedRef.current.disconnect();
          appstreamEmbedRef.current = null;
        }
      } catch (error) {
        console.error("Error while disconnecting AppStream:", error);
      }
    };
  }, [sessionURL]);

  return openedInSeparateTab ?  <div id="appstream-container" ref={containerRef} style={{ width: window.screen.width, height: window.screen.height }} /> : <div id="appstream-container" ref={containerRef} style={{ width: "100%", height: "100%" }} />
   
};

export default AppStreamEmbed;
