import {
  HvAvatar,
  HvBox,
  HvContainer,
  HvSection,
  HvTypography,
} from "@hitachivantara/uikit-react-core";
import { Backpack, Email, Schema } from "@hitachivantara/uikit-react-icons";

const UserInfo = () => {
  return (
    <HvSection style={{ flex: 1 }}>
      <HvAvatar>BA</HvAvatar>
      <HvBox style={{ paddingTop: "10px" }}>
        <HvTypography style={{ paddingTop: "10px" }}>About</HvTypography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Backpack></Backpack>
          <HvTypography>Software Engineer</HvTypography>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Schema></Schema>
          <HvTypography>Hv Product Management</HvTypography>
        </div>
        <HvTypography style={{ paddingTop: "10px" }}>Contact</HvTypography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Email></Email>
          <HvTypography>user.user@hitachivantara.com</HvTypography>
        </div>
      </HvBox>
    </HvSection>
  );
};

export default UserInfo;
