import React from 'react';
import PropTypes from 'prop-types';
import { HvGrid, HvSection, HvButton } from '@hitachivantara/uikit-react-core';
import { useHistory } from 'react-router';
const Footer = props => {
    const history = useHistory();
    const isLastStep = props?.lastStep
    const buttonText = isLastStep ? 'Submit' : 'Save & Next'
    const handleSumbit = (event) => {
        props.handleSumbit(event)
    }

    return (
        <div>
            <HvSection style={{ position: "static" }}>
                <HvGrid container>
                    <HvGrid item xs={6}>
                        <HvButton variant='primarySubtle' onClick={() => { history.push('/admin/masters/labs') }}>Exit</HvButton></HvGrid>
                    <HvGrid item xs={6} style={{ display: "flex", justifyContent: "center" }}>
                        {
                            !isLastStep ?  <HvButton variant='primarySubtle' type='submit' onSubmit={(event) => { 
                                handleSumbit(event) }}> {buttonText}</HvButton> : <HvButton variant='primarySubtle'  onClick={() => { history.push('/admin/masters/labs') }}> {buttonText}</HvButton>
                        }
                       
                    </HvGrid>
                </HvGrid>
            </HvSection>
        </div>
    );
};

Footer.propTypes = {
    handleSumbit: PropTypes.any
};

export default Footer;