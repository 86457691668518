import React, { useEffect, useState, useRef } from "react";
import LabMasterService from "services/admin/masters/LabService";
import {
  HvGrid,
  HvPanel,
  HvInput,
  HvTypography,
  HvSelectionList,
  HvListItem,
  HvButton,
  HvDropdown,
} from "@hitachivantara/uikit-react-core";
import Footer from "components/footer/Footer";
import {
  DropDownXS,
  DropLeft,
  DropRight,
  DropRightXS,
} from "@hitachivantara/uikit-react-icons";
import { useDispatch } from "react-redux";
import { useStyles } from "utils/theme";
import { showBanner } from "redux/actions/bannerActions";
import { timers } from "constants/admin/masters/labs";
import _ from "lodash";

const PermissionSteps = (props) => {
  const classes = useStyles();
  const labMasterServiceObj = new LabMasterService();
  const dispatch = useDispatch();
  const [groupUserList, setGroupUserList] = useState([]);
  const [initalGroupUserList, setInitalGroupUserList] = useState([]);
  const [selectedGroupUserList, setSelectedGroupUserList] = useState({
    group: [],
    user: [],
  });
  const [initialSelectedGroupUserList, setInitialSelectedGroupUserList] =
    useState({
      group: [],
      user: [],
    });
  const { user_permissions, group_permissions } = props?.labData
    ? props?.labData
    : [];
  const { currentStep } = props;
  const [labId] = useState(props?.labData?.id);
  const [permissionConfig, setPermissionConfig] = useState({
    max_time: 0,
    max_extension: 0,
    allowed_from: null,
    allowed_to: null,
    extension_time: 0,
  });
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemGroup, setSelectedItemGroup] = useState();
  const [selectedGroupUserItem, setSelectedGroupUserItem] = useState([]);
  const [selectedUserItem, setSelectedUserItem] = useState([]);
  const [selectedGroupAllowed] = useState([]);
  const [selectedUserAllowed] = useState([]);
  const [dropdownTimer, setDropDownTimer] = useState(timers);
  const [showNumberOfExtension, setShowNumberOfExtension] = useState(false);
  const selectedGroupUserListRef = useRef();
  let seletectedGroup;
  let selecteduser;
  const [errors, setErrors] = useState("");
  const [errorDuration, setErrorDuration] = useState("");
  const getUniqueUser = (inputArray) => {
    const uniqueObjects = {};
    inputArray.forEach((obj) => {
      // Iterate through each key-value pair in the object
      Object.keys(obj).forEach((key) => {
        const id = obj[key].id;
        // If the id doesn't exist in uniqueObjects, add the object
        if (!uniqueObjects[id]) {
          uniqueObjects[id] = obj[key];
        }
      });
    });
    const uniqueArray = Object.values(uniqueObjects);
    return uniqueArray;
  };

  useEffect(() => {
    labMasterServiceObj
      .getAllGroupsWithUsers()
      .then((response) => {
        setGroupUserList(
          response.map((item) => {
            return { id: item.id, label: item.name, children: item.users };
          })
        );
        setInitalGroupUserList(
          response.map((item) => {
            return { id: item.id, label: item.name, children: item.users };
          })
        );
        if (group_permissions?.length > 0) {
          seletectedGroup = group_permissions.map((group) => {
            return response.find((item) => {
              if (item?.id === group?.group_id) return item;
            });
          });
        }
        if (user_permissions?.length > 0) {
          selecteduser = user_permissions
            .map((user) => {
              return response.map((item) => {
                return item.users.find((userItem) => {
                  if (userItem?.id === user?.user_id) return userItem;
                });
              });
            })
            ?.map((obj) =>
              Object.fromEntries(
                Object.entries(obj).filter(([_, value]) => value !== undefined)
              )
            )
            .filter((obj) => Object.keys(obj).length !== 0);
        }
        // Remove duplicate objects
        let uniqueSelectedUsers = [];
        if (selecteduser?.length > 0) {
          uniqueSelectedUsers = getUniqueUser(selecteduser);
        }

        setSelectedGroupUserList({
          ...selectedGroupUserList,
          group:
            seletectedGroup?.length > 0
              ? seletectedGroup?.map((item) => {
                  return {
                    id: item.id,
                    label: item.name,
                    children: item.users,
                  };
                })
              : [],
          user: uniqueSelectedUsers?.length > 0 ? uniqueSelectedUsers : [],
        });
        setInitialSelectedGroupUserList({
          ...initialSelectedGroupUserList,
          group:
            seletectedGroup?.length > 0
              ? seletectedGroup?.map((item) => {
                  return {
                    id: item.id,
                    label: item.name,
                    children: item.users,
                  };
                })
              : [],
          user: uniqueSelectedUsers?.length > 0 ? uniqueSelectedUsers : [],
        });

        selectedGroupUserListRef.current = {
          group: seletectedGroup?.map((item) => {
            return { id: item.id, label: item.name, children: item.users };
          }),
          user: uniqueSelectedUsers,
        };
      })
      .catch((err) => {
        console.log(err);
      });
    if (
      user_permissions &&
      user_permissions.length > 0 &&
      user_permissions[0].id
    ) {
      labMasterServiceObj
        .getPermissionUserConfiguration(user_permissions[0].id)
        .then((response) => {
          if (response) {
            setPermissionConfig({
              max_time: response?.max_time,
              max_extension: response?.max_extension,
              extension_time: response?.extension_time,
            });
            setDropDownTimer(
              timers.map((item) => {
                if (item.label === response?.extension_time) {
                  return { ...item, selected: true };
                } else {
                  return { ...item, selected: false };
                }
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (
      group_permissions &&
      group_permissions.length > 0 &&
      group_permissions[0].id
    ) {
      labMasterServiceObj
        .getPermissionGroupConfiguration(group_permissions[0].id)
        .then((response) => {
          if (response) {
            setPermissionConfig(response);
            response?.max_time > 0
              ? setShowNumberOfExtension(true)
              : setShowNumberOfExtension(false);
            setDropDownTimer(
              timers.map((item) => {
                if (item.label === response?.extension_time) {
                  return { ...item, selected: true };
                } else {
                  return { ...item, selected: false };
                }
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {};
  }, []);

  const handleClick = (id) => {
    if (selectedItem === id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(id);
    }
  };

  const handleSelectedClick = (id) => {
    if (selectedItemGroup === id) {
      setSelectedItemGroup(null);
    } else {
      setSelectedItemGroup(id);
    }
  };
  const selectAndMoveGroupUserRight = (item) => {
    const index = selectedGroupUserItem?.findIndex(
      (element) => element?.id === item?.id
    );
    if (index >= 0) {
      setSelectedGroupUserItem(
        selectedGroupUserItem
          .slice(0, index)
          .concat(selectedGroupUserItem.slice(index + 1))
      );
    } else {
      selectedGroupUserItem.push(item);
    }
  };
  const selectAndMoveUserRight = (item) => {
    const index = selectedUserItem?.findIndex(
      (element) => element?.id === item?.id
    );
    if (index >= 0) {
      setSelectedUserItem(
        selectedUserItem
          .slice(0, index)
          .concat(selectedUserItem.slice(index + 1))
      );
    } else {
      selectedUserItem.push(item);
    }
  };
  function findMatchingArrays(array1, array2) {
    const matchingArray = [];
    // Iterate over array2
    array2.forEach((obj2) => {
      // Find matching object in array1 by ID
      const matchingObj = array1.find((obj1) => obj1.id === obj2.id);
      if (matchingObj) {
        // If matching object found, push it to newArray
        matchingArray.push(matchingObj);
      }
    });

    return { matchingArray };
  }

  function findMatchingUserInGroup(array1, array2) {
    const matchinUserInGroup = [];
    // Iterate over array2
    array2.forEach((obj2) => {
      // Find matching object in array1 by ID
      const matchingObj = array1.find((obj1) =>
        obj2?.children.find((user) => obj1?.id === user?.id)
      );
      if (matchingObj) {
        // If matching object found, push it to newArray
        matchinUserInGroup.push(matchingObj);
      }
    });

    return { matchinUserInGroup };
  }
  const moveItemToRight = () => {
    if (selectedGroupUserItem.length > 0) {
      setErrors(null);
      const { matchingArray } = findMatchingArrays(
        selectedGroupUserItem,
        selectedGroupUserList?.group
      );
      if (matchingArray.length > 0) {
        dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: { message: "Group already exist." },
              variant: "error",
            },
          })
        );
        return;
      }
    }
    if (selectedUserItem.length > 0) {
      const { matchingArray } = findMatchingArrays(
        selectedUserItem,
        selectedGroupUserList?.user
      );
      const { matchinUserInGroup } = findMatchingUserInGroup(
        selectedUserItem,
        selectedGroupUserList?.group
      );
      if (matchingArray.length > 0) {
        dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: { message: "user already exist." },
              variant: "error",
            },
          })
        );
        return;
      }
    }

    setSelectedGroupUserList({
      ...selectedGroupUserList,
      group: [...selectedGroupUserList.group, ...selectedGroupUserItem],
      user: [...selectedGroupUserList.user, ...selectedUserItem],
    });
    selectedGroupUserListRef.current = {
      group: [...selectedGroupUserList.group, ...selectedGroupUserItem],
      user: [...selectedGroupUserList.user, ...selectedUserItem],
    };
  };

  const selectAndMoveUserLeft = (item) => {
    selectedUserAllowed.push(item);
  };

  const selectAndMoveGroupUserLeft = (item) => {
    selectedGroupAllowed.push(item);
  };
  const moveItemToLeft = () => {
    setSelectedGroupUserList({
      ...selectedGroupUserList,
      group:
        selectedGroupAllowed.length > 0
          ? selectedGroupUserList.group.filter(
              (item) =>
                !selectedGroupAllowed.map((item) => item.id).includes(item.id)
            )
          : selectedGroupUserList.group,
      user:
        selectedUserAllowed.length > 0
          ? selectedGroupUserList.user.filter(
              (item) =>
                !selectedUserAllowed.map((item) => item.id).includes(item.id)
            )
          : selectedGroupUserList.user,
    });

    if (
      selectedGroupUserList?.group.length === 0 &&
      selectedGroupUserList?.user.length === 0
    ) {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: {
              message: "Allowed Groups/User list is Empty. Nothing to move.",
            },
            variant: "error",
          },
        })
      );
    }
  };
  const renderUserList = groupUserList?.map((item, index) => {
    return (
      <>
        <HvSelectionList
          key={index}
          multiple={true}
          label={
            <>
              <HvSelectionList
                multiple={true}
                onChange={() => {
                  selectAndMoveGroupUserRight(item);
                }}
              >
                <HvListItem
                  endAdornment={
                    selectedItem === item?.id ? (
                      <DropDownXS
                        onClick={() => {
                          handleClick(item?.id);
                        }}
                      />
                    ) : (
                      <DropRightXS
                        onClick={() => {
                          handleClick(item?.id);
                        }}
                      />
                    )
                  }
                >
                  {item?.label} ({item.children?.length})
                </HvListItem>
              </HvSelectionList>
            </>
          }
        >
          {selectedItem === item?.id ? (
            item.children.map((childItem, index) => {
              return (
                <HvListItem
                  key={index}
                  title="test"
                  onClick={() => {
                    selectAndMoveUserRight(childItem);
                  }}
                  value={childItem?.id}
                  style={{ height: "45px" }}
                >
                  {
                    <HvTypography variant="normalText">
                      {childItem?.full_name}
                      <br />
                      {childItem?.email}
                    </HvTypography>
                  }
                </HvListItem>
              );
            })
          ) : (
            <></>
          )}
        </HvSelectionList>
      </>
    );
  });

  const renderSelectedGroupList = () => {
    if (selectedGroupUserList?.group?.length > 0) {
      return selectedGroupUserList?.group.map((item, index) => {
        return (
          <>
            <HvSelectionList
              key={index}
              multiple={true}
              label={
                <>
                  <HvSelectionList
                    multiple={true}
                    onChange={() => {
                      selectAndMoveGroupUserLeft(item);
                    }}
                  >
                    <HvListItem
                      endAdornment={
                        selectedItemGroup === item?.id ? (
                          <DropDownXS
                            onClick={() => {
                              handleSelectedClick(item?.id);
                            }}
                          />
                        ) : (
                          <DropRightXS
                            onClick={() => {
                              handleSelectedClick(item?.id);
                            }}
                          />
                        )
                      }
                    >
                      {item?.label} ({item.children?.length})
                    </HvListItem>
                  </HvSelectionList>
                </>
              }
            >
              {selectedItemGroup === item?.id ? (
                item.children.map((childItem, index) => {
                  return (
                    <HvListItem
                      key={index}
                      title="test"
                      value={childItem?.id}
                      style={{ height: "45px" }}
                    >
                      {
                        <HvTypography variant="normalText">
                          {childItem?.full_name}
                          <br />
                          {childItem?.email}
                        </HvTypography>
                      }
                    </HvListItem>
                  );
                })
              ) : (
                <></>
              )}
            </HvSelectionList>
          </>
        );
      });
    } else {
      return <></>;
    }
  };

  const renderSelectedUserList = selectedGroupUserList?.user.map(
    (item, index) => {
      return (
        <HvListItem
          key={index}
          id={item?.id}
          value={item?.id}
          style={{ height: "45px" }}
          onClick={() => {
            selectAndMoveUserLeft(item);
          }}
        >
          {
            <HvTypography variant="normalText">
              {item?.full_name}
              <br />
              {item?.email}
            </HvTypography>
          }
        </HvListItem>
      );
    }
  );

  function search(query) {
    if (query.trim() === "") {
      return initalGroupUserList;
    }
    return groupUserList
      .map((item) => ({
        ...item,
        children: item.children.filter(
          (child) =>
            (child.full_name &&
              child.full_name.toLowerCase().includes(query.toLowerCase())) ||
            (child.email &&
              child.email.toLowerCase().includes(query.toLowerCase()))
        ),
      }))
      .filter((item) => item.children.length > 0);
  }

  const filterGroupUserList = (e) => {
    const filteredGroupList = search(e?.target.value);
    if (filterGroupUserList?.length > 0) {
      setGroupUserList(filteredGroupList);
    } else {
      setGroupUserList(initalGroupUserList);
    }
  };

  const searchSelected = (query) => {
    if (query.trim() === "") {
      return selectedGroupUserList;
    }
    const filteredGroup = selectedGroupUserList?.group
      ?.map((item) => ({
        ...item,
        children: item.children.filter(
          (child) =>
            (child.full_name &&
              child.full_name.toLowerCase().includes(query.toLowerCase())) ||
            (child.email &&
              child.email.toLowerCase().includes(query.toLowerCase()))
        ),
      }))
      .filter((item) => item.children.length > 0);

    const filterSelectedUser = selectedGroupUserList.user.filter((item) => {
      if (
        item?.full_name?.toLowerCase().includes(query.toLowerCase()) ||
        item?.email?.toLowerCase().includes(query.toLowerCase())
      ) {
        return item;
      }
    });
    if (filterSelectedUser?.length > 0 || filteredGroup?.length > 0) {
      return {
        group: filteredGroup,
        user: filterSelectedUser,
      };
    } else {
      return {
        group: [],
        user: [],
      };
    }
  };

  const filterSelectedGroupUserList = (e) => {
    if (!_.isEmpty(e?.target.value)) {
      const filteredGroupUserList = searchSelected(e?.target.value);
      setSelectedGroupUserList(filteredGroupUserList);
    } else {
      setSelectedGroupUserList(selectedGroupUserListRef.current);
    }
  };

  const handlePermissionSave = () => {
    console.log(permissionConfig); // correct values saved here, values are all of integer types
    if (
      permissionConfig?.max_time > 0 &&
      (permissionConfig.max_extension < 0 ||
        permissionConfig?.max_extension === undefined ||
        permissionConfig?.max_extension === "")
    ) {
      setErrorDuration("Number of extensions cannot be empty or less than 0.");
      return;
    }

    if (permissionConfig?.max_time <= 0 && permissionConfig.max_extension > 0) {
      setErrorDuration(
        "Duration should be greater than 0 as number of extensions is greater than 0."
      );
      return;
    }

    const payload = {
      lab: labId,
      group: selectedGroupUserList.group.map((element) => {
        return element?.id;
      }),
      user: selectedGroupUserList.user.map((element) => {
        return element?.id;
      }),
      max_time: permissionConfig.max_time ? permissionConfig.max_time : 0,
      max_extension: permissionConfig.max_extension
        ? permissionConfig.max_extension
        : 0,
      extension_time: permissionConfig.extension_time
        ? permissionConfig.extension_time
        : 0,
    };
    if (
      selectedGroupUserList.user.length === 0 &&
      selectedGroupUserList.group.length === 0
    ) {
      const userId = user_permissions[0]?.id;
      const groupId = group_permissions[0]?.id;

      if (userId) {
        labMasterServiceObj
          .deleteLabUserPermission(userId)
          .then(() => {
            //props.setNextStep(currentStep + 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (groupId) {
        labMasterServiceObj
          .deleteLabGroupPermission(groupId)
          .then(() => {
            //props.setNextStep(currentStep + 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setErrors("Allowed Groups/User list is Empty. Nothing to move.");
      // return true;
    } else {
      if (
        selectedGroupUserList.user &&
        selectedGroupUserList.user?.length > 0 &&
        user_permissions.length === 0 &&
        user_permissions
      ) {
        labMasterServiceObj
          .createLabUserPermission(payload)
          .then((response) => {
            if (response) {
              props.setNextStep(currentStep + 1);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (
        user_permissions &&
        user_permissions.length > 0 &&
        (selectedGroupUserList.user.length > 0 ||
          selectedGroupUserList.user.length === 0)
      ) {
        const userId = user_permissions[0].id;
        if (selectedGroupUserList.user.length === 0) {
          labMasterServiceObj
            .deleteLabUserPermission(userId)
            .then(() => {
              props.setNextStep(currentStep + 1);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          labMasterServiceObj
            .updateLabUserPermission(payload, userId)
            .then((response) => {
              if (response) {
                props.setNextStep(currentStep + 1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (
        group_permissions &&
        group_permissions.length === 0 &&
        selectedGroupUserList.group &&
        selectedGroupUserList.group?.length > 0
      ) {
        const payload = {
          lab: labId,
          group: selectedGroupUserList.group.map((element) => {
            return element?.id;
          }),
          max_time: permissionConfig.max_time ? permissionConfig.max_time : 0,
          max_extension: permissionConfig.max_extension
            ? permissionConfig.max_extension
            : 0,
          extension_time: permissionConfig.extension_time
            ? permissionConfig.extension_time
            : 0,
        };
        labMasterServiceObj
          .createLabGroupPermission(payload)
          .then((response) => {
            if (response) {
              props.setNextStep(currentStep + 1);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (
        group_permissions &&
        group_permissions.length === 1 &&
        selectedGroupUserList.group?.length === 0
      ) {
        const groupId = group_permissions[0].id;
        labMasterServiceObj
          .deleteLabGroupPermission(groupId)
          .then(() => {
            props.setNextStep(currentStep + 1);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        if (
          group_permissions &&
          group_permissions.length > 0 &&
          selectedGroupUserList.group?.length > 0
        ) {
          const groupId = group_permissions[0].id;
          const payload = {
            lab: labId,
            group: selectedGroupUserList.group.map((element) => {
              return element?.id;
            }),
            max_time: permissionConfig.max_time ? permissionConfig.max_time : 0,
            max_extension: permissionConfig.max_extension
              ? permissionConfig.max_extension
              : 0,
            extension_time: permissionConfig.extension_time > 0 && parseInt(permissionConfig.max_extension) > 0
              ? permissionConfig.extension_time
              : 0,
          };
          labMasterServiceObj
            .updateLabGroupPermission(payload, groupId)
            .then((response) => {
              if (response) {
                props.setNextStep(currentStep + 1);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
      if (
        group_permissions &&
        group_permissions.length > 0 &&
        selectedGroupUserList.group?.length === 0
      ) {
        const groupId = group_permissions[0].id;
        labMasterServiceObj
          .deleteLabGroupPermission(groupId)
          .then(() => {
            props.setNextStep(currentStep + 1);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    props.setNextStep(currentStep + 1);
  };
  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handlePermissionSave();
        }}
      >
        <HvGrid container style={{ paddingBottom: "20px" }}>
          <HvGrid item xs={4} md={4} xl={4} sm={3}>
            <HvTypography variant="sTitle" style={{ padding: "20px" }}>
              All groups/users
            </HvTypography>
            <HvPanel style={{ height: "400px" }}>
              <HvInput
                aria-label="Search"
                placeholder="Search"
                type="Search"
                onChange={filterGroupUserList}
              />
              <HvSelectionList name="All groups/users" multiple={true}>
                {groupUserList && renderUserList}
              </HvSelectionList>
            </HvPanel>
          </HvGrid>
          <HvGrid item xs={1} md={1} xl={1} sm={1} alignContent={"center"}>
            <HvButton variant="primaryGhost" onClick={moveItemToRight}>
              <DropRight />
            </HvButton>
            <HvButton variant="primaryGhost" onClick={moveItemToLeft}>
              <DropLeft />
            </HvButton>
          </HvGrid>
          <HvGrid item xs={3} md={3} xl={3} sm={3}>
            <HvTypography variant="sTitle" style={{ padding: "20px" }}>
              Allowed groups/users
            </HvTypography>
            <HvPanel>
              <HvInput
                aria-label="Search"
                placeholder="Search"
                type="Search"
                onChange={filterSelectedGroupUserList}
              />
              <HvSelectionList name="Allowed Group/User" multiple={true}>
                {selectedGroupUserList && renderSelectedGroupList()}
                {selectedGroupUserList && renderSelectedUserList}
              </HvSelectionList>
            </HvPanel>
            {errors && errors !== undefined ? (
              <p className={classes.errorLabel}>{errors}</p>
            ) : null}
          </HvGrid>
          <HvGrid item xs={1} md={1} xl={2} sm={1} lg={2} alignContent={"Top"}>
            {errorDuration && errorDuration !== undefined ? (
              <p className={classes.errorLabel}>{errorDuration}</p>
            ) : null}
            <br />
            <HvInput
              label="Duration"
              placeholder="Duration"
              type="text"
              value={permissionConfig?.max_time}
              onChange={(e) => {
                setPermissionConfig({
                  ...permissionConfig,
                  max_time: e.target.value,
                });
                if (permissionConfig.max_time > 0 || e.target.value > 0) {
                  setErrorDuration(null);
                  setShowNumberOfExtension(true);
                }
              }}
            />

            <br />
            {showNumberOfExtension ? (
              <>
                <HvInput
                  label="Number of Extensions"
                  placeholder="Number of Extensions"
                  type="text"
                  value={permissionConfig?.max_extension}
                  onChange={(e) => {
                    const newMaxExtension = e.target.value;
                    setPermissionConfig({
                      ...permissionConfig,
                      max_extension: newMaxExtension,
                      extension_time: permissionConfig.extension_time
                    });
                  }}
                />

                <br />
                {permissionConfig?.max_extension > 0 && (
                  <HvDropdown
                    values={dropdownTimer}
                    label={"Extension time (mins)"}
                    placeholder={30}
                    onChange={(e) => {
                      setPermissionConfig({
                        ...permissionConfig,
                        extension_time: e?.value ? e?.value : 30,
                      });
                      setDropDownTimer(
                        timers.map((item) => {
                          if (item.label === e?.label) {
                            return { ...item, selected: true };
                          } else {
                            return { ...item, selected: false };
                          }
                        })
                      );
                    }}
                    onLoad={()=>{
                      setPermissionConfig({
                        ...permissionConfig,
                        extension_time: permissionConfig.extension_time > 0 ? permissionConfig.extension_time : 30
                      });
                    }}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </HvGrid>
        </HvGrid>
        <Footer
          handleSumbit={(event) => {
            event?.preventDefault();
          }}
        />
      </form>
    </div>
  );
};

PermissionSteps.propTypes = {};

export default PermissionSteps;
