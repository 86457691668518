import React, { useEffect, useState, Fragment } from 'react';
import {
    HvTable, hvTextColumn, HvPagination,
    HvTableBody,
    HvTableCell,
    HvTableContainer,
    HvTableHead,
    HvTableHeader,
    HvTableRow, HvEmptyState, useHvData, useHvPagination, hvNumberColumn, hvDateColumn
} from "@hitachivantara/uikit-react-core";
import { Ban } from '@hitachivantara/uikit-react-icons';
import _ from "lodash";
import PropTypes from 'prop-types';
import LabService from 'services/LabService';
import { buildQueryParams } from 'utils/common';

const CleanupAWSTable = (props) => {
    const [columns, setColumns] = useState([]);
    const [totalrecords, setTotalRecords] = useState();
    const [data, setData] = useState(null);
    const labServiceObj = new LabService();

    const { getTableProps, getTableBodyProps, prepareRow, headers, headerGroups , page, gotoPage, rows, state: { pageSize, pageIndex, sortBy }, getHvPaginationProps } =
        useHvData(
            {
                columns,
                data,
                manualPagination: true,
                manualSortBy: true,
                pageCount: totalrecords
            },
            useHvPagination
        );

    useEffect(() => {
        setColumns([
            [
                hvTextColumn({
                    Header: "User Name",
                    accessor: "user",
                    cellType: "alpha-numeric"
                }),
                hvDateColumn({
                    Header: "CleanUp Start Date",
                    accessor: "cleanup_start_time",
                   
                },"DD/MM/YYYY HH:MM"),
                hvDateColumn({
                    Header: "CleanUp End Date",
                    accessor: "cleanup_end_time"
                    
                },"DD/MM/YYYY HH:MM"),
                hvTextColumn({
                    Header: "Status",
                    accessor: "cleanup_status",
                    cellType: "alpha-numeric",
                    Cell: (cellData) => {
                        if (!_.isEmpty(cellData)) {
                            const statusColor = cellData.row.original.cleanup_status === 'PASS' ? 'green' : 'red';
                            return (
                                <span style={{ color: statusColor }}>{cellData.row.original.cleanup_status}</span>
                            );
                        }
                    },
                    
                }),
                hvNumberColumn({
                    Header: "Session",
                    accessor: "session",
                    cellType: "numeric"
                })
               
            ]
        ][0]);
        try {
            const param = {
                lab_id : props?.labId,
                offset : pageSize * ((pageIndex + 1) - 1),
                limit : pageSize
            }
            labServiceObj.getAWSCleanUpData(buildQueryParams(param)).then((response)=>{
                if(response){
                    const tableData = response.data.results?.map((item) => {
                             let output = {};
                             if (!_.isNull(item)) {
                               output.cleanup_end_time = item?.cleanup_end_time;
                               output.cleanup_start_time = item?.cleanup_start_time;
                               output.cleanup_status = item?.cleanup_status;
                               output.session = item?.session;
                               output.user = item?.user;
                               return output;
                             }
                           });
                           setData(tableData);
                           setTotalRecords(Math.ceil(response.data?.count / pageSize));
                }
            })
        } catch (error) {
            console.log("error");
        }
    }, [props?.labId, pageIndex, pageSize, ])

  useEffect(() => {
       gotoPage(0)
    }, [sortBy, gotoPage]);
    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState message="No data to display" icon={<Ban role="presentation" />} />
            </HvTableCell>
        </HvTableRow>
    );

    return (
        <div>
            <HvTableContainer>
                <HvTable {...getTableProps()}>
                    <HvTableHead>
                        {headerGroups.map((headerGroup) => (
                            <HvTableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((col) => (
                                    <HvTableHeader {...col.getHeaderProps()}>
                                        {col.render("Header")}
                                    </HvTableHeader>
                                ))}
                            </HvTableRow>
                        ))}
                    </HvTableHead>
                    <HvTableBody {...getTableBodyProps()}>
                        {
                            rows.length === 0 ? <EmptyRow /> :
                                rows.map((row) => {

                                    prepareRow(row);

                                    return (
                                        <Fragment key={row.id}>
                                            <HvTableRow {...row.getRowProps()}>
                                                {row.cells.map((cell) => (
                                                    <HvTableCell {...cell.getCellProps()}>
                                                        {cell.render("Cell")}
                                                    </HvTableCell>
                                                ))}
                                            </HvTableRow>

                                        </Fragment>
                                    );
                                })}
                    </HvTableBody>
                </HvTable>
            </HvTableContainer>
            <HvPagination
                {...getHvPaginationProps()} />
        </div >

    );
};

CleanupAWSTable.propTypes = {
    labId :PropTypes.any

};

export default CleanupAWSTable;