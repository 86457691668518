/* eslint-disable no-useless-computed-key */
import React, { useState, useRef } from 'react';
import { HvAvatar, HvButton, HvHeader, HvHeaderActions, HvHeaderBrand, HvHeaderNavigation, HvDropdown, HvVerticalNavigationTree, HvTypography, rightControlClasses } from "@hitachivantara/uikit-react-core";
import {  useMediaQuery } from "@material-ui/core";
import { Menu } from "@hitachivantara/uikit-react-icons";
import { useSelector } from 'react-redux';
import NestedMenu from './headerDropdown';
import { HvDrawer } from '@hitachivantara/uikit-react-core';
import { LOGGED_IN_USER_INFO } from "constants/common";
import SearchBar from "components/searchBar";
import { debounce } from "lodash";
import { withRouter } from 'react-router';
import { classes } from './HeaderStyle';
import _ from 'lodash';
import {useTheme } from "@mui/material/styles";


const NewHeader = ({ location, history }) => {
  const fromSessionUSerInfo = sessionStorage.getItem(LOGGED_IN_USER_INFO);
  const inputRef = useRef("")
  const userInfo = JSON.parse(fromSessionUSerInfo);
  const [open, setOpen] = useState(false);
  const themeSelector = useSelector((state) => state.themeReducer.theme);
  const muiTheme = useTheme();

  const userName = userInfo && userInfo.full_name;
  const userLoggedIn = userInfo?.id > 0  ? true : false;
  const hasCustomer = userInfo?.groups?.map(item => item.name === "Customers");
  const isCustomer = hasCustomer?.includes(true);

  let firstName = '';
  let lastName = '';
  if (userName) {
    let fullNameArr = userName.split(' ');
    firstName = fullNameArr && fullNameArr[0];
    lastName = fullNameArr && fullNameArr[1];
  }
 
  let isLoginPage = location?.pathname === '/email-login' || location.pathname === "/" || location.pathname === "/instructionInTab"  

  const [selected, setSelected] = useState("home");
  const [showProfileMenu, setProfileMenu] = useState(false);
  const isMdUp = useMediaQuery(muiTheme.breakpoints.up("md"));
  const handleChange = (e, selectedItem) => {
    history.push(selectedItem?.url)
    setSelected(selectedItem.id);
    setOpen(false)
  };
  const redirectToHome = () => history.push('/labs');
  const redirectToLoginPage = () => history.push('/email-login');
  const toggleMenuProfile = () => setProfileMenu(!showProfileMenu);

  const redirectTo = (url) => {
    history.push(url);
  }


  const changeHandler = (event) => {
    const value = event.target.value ? event.target.value : inputRef.current?.value
     debounceSearchLabData(value);
    
  };
  const debounceSearchLabData = debounce(value => {
    history.push({
      pathname: '/labs',
      state: {
        searchedString: value,
      }
    });
  }, 1000);

  return (
    <HvHeader className={classes.root}>
      {!isMdUp && (
        <div>
          <HvButton style={{ width: 32, height: 32 }} icon >
            <Menu onClick={() => setOpen(true)} />
            <HvDrawer
              id="drawer-test"
              open={open}
              classes={{
                paper: classes.drawerPaper
              }}

              onChange={handleChange}
              hideBackdrop
            >
              <HvVerticalNavigationTree style={{
                paddingTop: "32px"
              }}
                data={[]}
                className={classes.mobileMenuFonts}
                onChange={handleChange}
                collapsible
              />
            </HvDrawer>
          </HvButton>
        </div>
      )
      }
      {
        isMdUp ? <><HvHeaderBrand className={classes.brand} logo={<img className={classes.logo} src={themeSelector === "dawn" ? "/assets/images/LOGO HitachiVantara.svg" : "/assets/images/LOGO HitachiVantara.svg"} alt={"Hitachi Logo"} onClick={redirectToHome} />} name={<HvTypography variant='body'>Automated Labs Online</HvTypography>} /></> : <></>
      }
      {
        isMdUp && (
          !isLoginPage ? <></> :
            <>
              <HvHeaderNavigation className={classes.root} data={[]} selected={selected} onClick={handleChange} />
            </>
        )
      }
      {
        isMdUp && (!isLoginPage ? <><SearchBar inputRef={inputRef} changeHandler={changeHandler} /></> : <></>)
      }
      {showProfileMenu && userLoggedIn && <NestedMenu userInfo={userInfo} setProfileMenu={toggleMenuProfile} />}

      {
        !isLoginPage
        && 
        <HvHeaderActions>
          {isMdUp && (
            !isLoginPage && !isCustomer &&
            <>
              <HvDropdown
                className={classes.dropdownMenuProperties}
                id="dropdown8"
                aria-label="No default"
                hasTooltips
                disablePortal
                values={[
                  {
                    id: "LabISVRequest",
                    label: "Co-Creation Request",
                    url: "/isv-request"
                  }
                ]}
                onChange={(item) => redirectTo(item.url)}
                placeholder={"Co-Create Instructions"} />
            </>
          )}

          {!userLoggedIn ? <HvAvatar className={classes.avatar} backgroundColor="acce1" size="MD" onClick={redirectToLoginPage} /> :
            <>
              <HvAvatar className={classes.avatar} backgroundColor="secondary" size="MD" onClick={toggleMenuProfile}>
                {firstName?.substr(0, 1)}{lastName?.substr(0, 1)}
              </HvAvatar>
            </>
          }
        </HvHeaderActions>
      }

    </HvHeader >
  );

}

export default withRouter(NewHeader);