import {
  HvButton,
  HvTypography,
  HvBox,
  HvContainer,
  HvBanner,
  HvStack,
  HvDialog,
  HvDialogContent,
  HvDialogTitle,
  HvDialogActions,
  HvGrid,
  HvInput,
  HvTextArea,
  HvRadioGroup,
  HvRadio,
} from "@hitachivantara/uikit-react-core";
import { useForm } from "hooks/useForm";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { backdoorLogin } from "redux/actions/loginActions";
import { classes } from "./style";
import { useAuth0 } from "@auth0/auth0-react";
import StorageService from "services/StorageService";
import { doSsoLogin } from "redux/actions/loginActions";
import { showBanner } from "redux/actions/bannerActions";
import LoginService from "services/LoginService";
import _ from "lodash";
import { showLoader } from "redux/actions/commonActions";
import { generateState } from "@okta/okta-auth-js";
import { useSelector } from "react-redux";
import { getAndSetTokenExpiry } from "utils/common";
import { setTokenExpiry } from "redux/actions/loginActions";
import CommonService from "services/CommonService";
import { Download, Info, Next } from "@hitachivantara/uikit-react-icons";
import {
  HOME_PAGE_TITLE1,
  HOME_PAGE_TITLE10,
  HOME_PAGE_TITLE11,
  HOME_PAGE_TITLE12,
  HOME_PAGE_TITLE13,
  HOME_PAGE_TITLE2,
  HOME_PAGE_TITLE3,
  HOME_PAGE_TITLE4,
  HOME_PAGE_TITLE5,
  HOME_PAGE_TITLE6,
  HOME_PAGE_TITLE7,
  HOME_PAGE_TITLE8,
  HOME_PAGE_TITLE9,
} from "constants/common";
import HomePageCard from "./HomePageCard";
import TotalLabsCard from "./TotalLabsCard";
import CategoryCard from "./CategoryCard";
import ReactPlayerLoader from "@brightcove/react-player-loader";

const prevLabUrl = localStorage.getItem("LabUrl");
const storageServiceObj = new StorageService();
const loginServiceObj = new LoginService();
const commonServiceObj = new CommonService();

const Login = () => {
  const [bannerMessage, setBannerMessage] = useState();
  const [openBanner, setOpenBanner] = useState(false);
  const [openBackDoorBanner, setOpenBackDoorBanner] = useState(false);
  const [openContactUs, setOpenContactUs] = useState(false);
  const [labCategoriesInfo, setLabCategoriesInfo] = useState();
  const [totalLabsRun, setTotalLabsRun] = useState();
  const [totalHoursUsed, setTotalHoursUsed] = useState();
  const [postData, setPostData] = useState({});
  const [tabIndex, setTabIndex] = useState(0);
  const isInitialRender = useRef(true);
  const dispatch = useDispatch();
  // const classes = useStyles();
  let history = useHistory();
  const expiryTime = useSelector((state) => state.loginReducer.expiryTime);
  const {
    loginWithRedirect,
    getAccessTokenSilently,
    isAuthenticated,
    logout,
    getIdTokenClaims,
  } = useAuth0();

  const videoURL = process.env.REACT_APP_HOMEPAGE_VIDEO_URL;
  const cardData = [
    {
      header: "Foundational Product Experience",
      description: [
        "Virtual Hardware Solutions",
        "Essential Product Functionality",
        "Guided Step-by-Step Instructions",
      ],
    },
    {
      header: "Advanced Product Experience",
      description: [
        "Physical Hardware Solutions",
        "Comprehensive Product Capabilities",
        "Guided Step-by-Step Instructions",
        "Fully Automated Setup and Cleanup",
      ],
    },
    {
      header: "Integrated Solution Co-Creation",
      description: [
        "Customized Co-Creation Lab",
        "Integration with ISV/IHV Solutions",
        "Collaboration with Hitachi Experts",
      ],
    },
  ];

  const labCategoryImages = [
    "/assets/images/HybridCloud.png",
    "/assets/images/DataResilieasce.png",
    "/assets/images/UniversalData.png",
  ];

  useEffect(() => {
    loginServiceObj
      .getLabCategoriesInfo()
      .then((response) => {
        if (response) {
          setLabCategoriesInfo(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    loginServiceObj
      .getTotalLabsRunTillDate()
      .then((response) => {
        if (response) {
          const formattedTotalLabsRun =
            response?.total_session?.toLocaleString();
          const formattedTotalHoursUsed =
            response?.total_used_time?.toLocaleString();
          setTotalLabsRun(formattedTotalLabsRun);
          setTotalHoursUsed(formattedTotalHoursUsed);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const categoryData = labCategoriesInfo?.results?.map((item, index) => ({
    header: item.name,
    description: item.description,
    image: labCategoryImages[index],
  }));

  useEffect(() => {
    const handleAuthCallback = async () => {
      const storedState = localStorage.getItem("ssoState");
      if (isAuthenticated && storedState) {
        const isValidState = validateState(storedState);
        if (isValidState) {
          authenticateOkta();
        } else {
          console.error("Invalid state detected!");
        }
      }
    };

    handleAuthCallback();
  }, [isAuthenticated]);

  useEffect(() => {
    commonServiceObj
      .getBannerMessage()
      .then((response) => {
        if (response) {
          setBannerMessage(response?.message);
          setOpenBanner(!openBanner);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(()=>{
    const urlParams = new URLSearchParams(window.location.search);
    const data = {};
    
    for (const [key, value] of urlParams.entries()) {
      data[key] = value; // Store key-value pairs in the `data` object
    }
    const payload = { data };
    if(!_.isEmpty(payload)){
      commonServiceObj.trackSocialMediaUsage(payload).then((response)=>{if (response) {
        console.log(response)
      }}).catch((error)=>{
        console.log(error)
      })
    }
   
  },[])
  const {
    handleSubmit: handleSubmitLogin, // handles form submission
    handleChange, // handles input changes
    data, // access to the form data
    errors, // includes the errors to show
  } = useForm({
    // the hook we are going to create
    initialValues: {
      // used to initialize the data
      email: "",
      password: "",
    },
    validations: {
      // all our validation rules go here
      email: {
        required: {
          value: true,
          message: "Email is required",
        },
        pattern: {
          value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
          message: "Email is not valid",
        },
      },
      password: {
        required: {
          value: true,
          message: "Password is required",
        },
        pattern: {
          value:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+{}[\]:;"'<>,.?/]).{8,15}$/,
          message:
            "Password length should be  8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.",
        },
      },
    },
    onSubmit: async () => {
      await dispatch(backdoorLogin(data));
      const userInfo = storageServiceObj.getUserData();
      if (!_.isEmpty(userInfo)) {
        history.push("/labs");
      }
    },
  });

  const onFailure = () => console.log("player error");
  const onSuccess = () => console.log("player success");

  const renderVideo = (videoUrl) => {
    if (videoUrl) {
      let urlParts = videoURL?.split("/");
      const accountId = urlParts[3];
      const playerId = urlParts[4].split("_")[0];
      const videoId = urlParts[5].split("?videoId=")[1];

      return (
        <ReactPlayerLoader
          accountId={accountId}
          videoId={videoId}
          playerId={playerId}
          onFailure={onFailure}
          onSuccess={onSuccess}
          embedOptions={{
            responsive: true,
            aspectRatio: "16:9",
            maxWidth: "100%",
          }}
          attrs={{
            responsive: true,
            autoplay: true,
            muted: true,
          }}
        />
      );
    } else {
      return (
        <HvTypography variant="selectedNavText">
          Error Loading Video
        </HvTypography>
      );
    }
  };

  const handleTabChange = (event) => {
    setTabIndex(Number(event.target.value));
  };

  const validateState = (storedState) => {
    return typeof storedState === "string" && storedState.trim() !== "";
  };

  const getIdTokenForAuthenticate = async (access_token) => {
    getIdTokenClaims().then((token) => {
      const tokenExpirationTime = getAndSetTokenExpiry(token);
      dispatch(setTokenExpiry(tokenExpirationTime));
      dispatch(showLoader(true));
      const payload = {
        encrypted_token: access_token,
        id_token: token.__raw,
      };
      loginServiceObj
        .verifySsoToken(payload)
        .then((result) => {
          if (result.id && result.access_token) {
            result["type"] = "sso";
            dispatch(doSsoLogin(result));
            if (!_.isEmpty(prevLabUrl)) {
              history.push(prevLabUrl);
            } else {
              history.push("/labs");
            }
          }

          dispatch(showLoader(false));
        })
        .catch(() => {
          dispatch(
            showBanner({
              payload: {
                showBanner: true,
                label: {
                  message:
                    "Error Occured in Authenticate Okta User, Please login again.",
                },
                variant: "error",
              },
            })
          );
          logoutWithRedirect();
        });
    });
  };

  const authenticateOkta = async () => {
    const token = await getAccessTokenSilently();
    getIdTokenForAuthenticate(token);
  };

  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_returnTo,
        clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
      },
    }).catch(() => {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: {
              message: "Error while logout through SSO, Please login again.",
            },
            variant: "error",
          },
        })
      );
    });

  const redirectToSSO = async () => {
    const state = generateState();
    localStorage.setItem("ssoState", state);
    await loginWithRedirect({
      appState: state,
    }).catch(() => {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: { message: "Error Occured in SSO login." },
            variant: "error",
          },
        })
      );
    });
  };

  const initiateLogin = () => {
    if (
      process.env.REACT_APP_CUSTOM_NODE_ENV === "development" ||
      process.env.REACT_APP_CUSTOM_NODE_ENV === "staging"
    ) {
      
      setOpenBackDoorBanner(!openBackDoorBanner);
    } else {
      redirectToSSO();
    }
  };
  const initiateBackDoorLogin = async () => {
    console.log("disabled");
  };

  const renderCards = () =>
    cardData.map((item) => <HomePageCard data={item} />);

  const renderCategoryCards = () =>
    categoryData?.map((item) => (
      <CategoryCard data={item} initiateLogin={initiateLogin} />
    ));

  const DialogForm = () => (
    <HvGrid container rowSpacing="xs">
      <HvGrid item xs={12}>
        <HvInput
          required
          name="businessEmailAddress"
          label="Business Email Address"
          // autoFocus
          placeholder="Enter email address"
          type="email"
          statusMessage="Enter a valid email address"
        />
      </HvGrid>
      <HvGrid item xs={12}>
        <HvInput
          required
          name="firstName"
          label="First Name"
          // autoFocus
          placeholder="Enter your first name"
        />
      </HvGrid>
      <HvGrid item xs={12}>
        <HvInput
          required
          name="lastName"
          label="last Name"
          // autoFocus
          placeholder="Enter your last name"
        />
      </HvGrid>

      <HvGrid item xs={12}>
        <HvTextArea
          required
          name="ReachingOutFor"
          label="Reaching Out For"
          rows={4}
          // autoFocus
        />
      </HvGrid>
    </HvGrid>
  );

  const submitContactUsForm = async () => {
    const payload = {
      email: postData.businessEmailAddress,
      first_name: postData.firstName,
      last_name: postData.lastName,
      message: postData.ReachingOutFor,
    };
    try {
      const response = await loginServiceObj.contactUsForm(payload);
      if (response) {
        dispatch(
          showBanner({
            showBanner: true,
            label: {
              message:
                "Thank you for your interest in HALO. Our team will reach out to you regarding your inquiry.",
            },
            variant: "success",
          })
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      if (postData) {
        submitContactUsForm();
      }
    }
  }, [postData]);

  return (
    <HvBox className={classes.mainContainer}>
      {bannerMessage ? (
        <HvBanner
          open={openBanner}
          offset={64}
          customIcon={<Info color="base_dark" />}
          className={classes.bannerlabel}
          label={bannerMessage}
          onClose={() => {
            setOpenBanner(!openBanner);
          }}
        ></HvBanner>
      ) : (
        <></>
      )}
      {openBackDoorBanner ? (
        <>
          <HvDialog
            open={openBackDoorBanner}
            onClose={() => {
              setOpenBackDoorBanner(false);
            }}
          >
            <HvDialogTitle variant="warning">Login</HvDialogTitle>
            <HvDialogContent>
              <HvDialogContent indentContent>
                For BackDoor login click Backdoor login, or use SSO login.
              </HvDialogContent>
              <HvDialogActions>
                <HvButton
                  variant="secondaryGhost"
                  onClick={() => {
                    setOpenBackDoorBanner(false);
                    initiateBackDoorLogin();
                  }}
                >
                  BackDoorLogin
                </HvButton>
                <HvButton
                  autoFocus
                  variant="secondaryGhost"
                  onClick={() => {
                    setOpenBackDoorBanner(false);
                    redirectToSSO();
                  }}
                >
                  SSO Login
                </HvButton>
              </HvDialogActions>
            </HvDialogContent>
          </HvDialog>
        </>
      ) : (
        <></>
      )}
      {openContactUs ? (
        <>
          <HvDialog
            disableBackdropClick
            open={openContactUs}
            onClose={() => setOpenContactUs(false)}
          >
            <HvDialogTitle>Get in Touch</HvDialogTitle>
            <HvDialogContent>
              <div style={{ marginBottom: 10 }}>All Fields are required.</div>
              <form
                id="create-ContactUs"
                onSubmit={(event) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formEntries = Object.fromEntries(formData.entries());
                  // Check if any input is invalid
                  const isValid = Array.from(
                    event.currentTarget.elements
                  ).every((element) => {
                    if (
                      element.hasAttribute("aria-invalid") &&
                      element.getAttribute("aria-invalid") === "true"
                    ) {
                      return false;
                    }

                    return true;
                  });
                  if (isValid) {
                    setPostData(formEntries);
                    setOpenContactUs(false);
                  }
                }}
              >
                <DialogForm />
              </form>
            </HvDialogContent>
            <HvDialogActions>
              <HvButton type="submit" form="create-ContactUs">
                Submit
              </HvButton>
              <HvButton
                variant="secondaryGhost"
                onClick={() => setOpenContactUs(false)}
              >
                Cancel
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </>
      ) : (
        <></>
      )}

      <HvBox className={classes.newHomePage}>
        <HvStack 
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
          spacing={"lg"}
          direction={{xl:"column"}}
        >
          <HvBox>
            <HvTypography
              variant="title1"
              style={{
                fontFamily: "fangsong",
                fontStyle: "italic",
                color: "white",
                paddingTop: "40px",
              }}
            >
              {HOME_PAGE_TITLE1}
            </HvTypography>
          </HvBox>
          <HvBox>
            <HvTypography
              variant="display"
              style={{ fontFamily: "fangsong", color: "white" }}
            >
              {HOME_PAGE_TITLE2}
            </HvTypography>
          </HvBox>
          <HvBox>
            <HvTypography
              variant="title3"
              style={{
                color: "white",
                width: "60vw",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {HOME_PAGE_TITLE3}
            </HvTypography>
          </HvBox>
          <HvBox style={{ display: "flex", gap: 20 }}>
            <HvButton
              onClick={initiateLogin}
              variant="negative"
              className={classes.exploreLab}
              size="xl"
              endIcon={<Next />}
            >
              Explore Labs &nbsp;&nbsp;
            </HvButton>
            <HvButton
              onClick={() => history.push("/UserGuide")}
              variant="negativeSubtle"
              size="xl"
              className={classes.eGuide}
              endIcon={<Download />}
            >
              Read the eBrochure &nbsp;&nbsp;
            </HvButton>
          </HvBox>
        </HvStack>
      </HvBox>

      <HvBox
        className={classes.footerContainer}
        style={{
          width: "100%",
          minHeight: "30vh", // Occupy 30% of the viewport height
          background: "#F4F5F5", // Add a background color for clarity
          paddingTop: "50px",
        }}
      >
        <HvStack
          direction="column"
          spacing="lg"
          style={{ paddingLeft: "100px", paddingBottom: "100px" }}
        >
          <HvBox>
            <HvTypography
              variant="title1"
              style={{
                fontFamily: "fangsong",
                fontStyle: "italic",
                color: "black",
              }}
            >
              {HOME_PAGE_TITLE4}
            </HvTypography>
          </HvBox>
          <HvBox>
            <HvTypography
              variant="display"
              style={{ fontFamily: "fangsong", color: "black", width: "50vw" }}
            >
              {HOME_PAGE_TITLE5}
            </HvTypography>
          </HvBox>
          <HvBox>
            {
              <HvGrid
                container
                justifyContent="flex-start"
                style={{ paddingRight: "100px" }}
              >
                <HvGrid
                  item
                  sx={12}
                  xs={12}
                  xl={12}
                  lg={12}
                  sm={12}
                  md={12}
                  flexDirection={"row"}
                  display={"flex"}
                  columnGap={10}
                >
                  {renderCards()}
                </HvGrid>
              </HvGrid>
            }
          </HvBox>
        </HvStack>
        <HvStack
          direction="column"
          spacing="lg"
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <HvBox
            style={{
              justifyContent: "center",
              alignItems: "center",
              width: "70%",
            }}
          >
            {renderVideo(videoURL)}
          </HvBox>
        </HvStack>

        <HvStack
          direction="column"
          spacing="lg"
          style={{
            paddingLeft: "100px",
            paddingTop: "100px",
          }}
        >
          <HvBox
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "100px",
            }}
          >
            <HvTypography
              variant="title1"
              style={{
                fontFamily: "fangsong",
                fontStyle: "italic",
                color: "black",
              }}
            >
              {HOME_PAGE_TITLE6}
            </HvTypography>
          </HvBox>

          <HvBox>
            <HvStack
              direction="row"
              spacing="lg"
              style={{ width: "100%", height: "100%" }}
            >
              <HvBox>
                <HvStack
                  direction="column"
                  spacing="lg"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <HvTypography
                    variant="display"
                    style={{
                      fontFamily: "fangsong",
                      color: "black",
                      width: "50vw",
                    }}
                  >
                    {tabIndex === 0 ? HOME_PAGE_TITLE12 : HOME_PAGE_TITLE7}
                  </HvTypography>
                  <HvTypography
                    variant="title3"
                    style={{
                      fontFamily: "fangsong",
                      color: "black",
                      width: "40vw",
                    }}
                  >
                    {tabIndex === 0
                      ? HOME_PAGE_TITLE13(totalHoursUsed)
                      : HOME_PAGE_TITLE8(totalLabsRun)}
                  </HvTypography>
                </HvStack>
              </HvBox>

              <HvBox
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center align items horizontally
                  justifyContent: "center", // Center align items vertically
                  height: "100%", // Ensure it takes the full height of its parent
                  paddingLeft: "100px",
                }}
              >
                <HvBox
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Center align items horizontally
                    textAlign: "center", // Center align text within the box
                  }}
                >
                  <HvRadioGroup
                    description=""
                    label="Choose to view statistics"
                    orientation="horizontal"
                    value={tabIndex}
                    onChange={handleTabChange}
                  >
                    <HvRadio value={0} label="Hours Run" />
                    <HvRadio value={1} label="Lab Instances" />
                  </HvRadioGroup>
                </HvBox>
                <TotalLabsCard
                  title={tabIndex === 0 ? "Hours Run" : "Lab Instances"}
                  totalUsage={tabIndex === 0 ? totalHoursUsed : totalLabsRun}
                />
              </HvBox>
            </HvStack>
          </HvBox>
        </HvStack>

        <HvBox
          style={{
            width: "100%",
            display: "flex",
            height: "100%",
            paddingTop: "200px",
            flexDirection: "column",
          }}
          className={classes.footerContainer}
        >
          <HvStack
            direction="column"
            spacing="lg"
            style={{ paddingBottom: "50px" }}
          >
            <HvBox
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <HvTypography variant="display" style={{ color: "black" }}>
                {HOME_PAGE_TITLE9}
              </HvTypography>
            </HvBox>
            <HvBox
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <HvTypography
                variant="title3"
                style={{
                  color: "black",
                  paddingLeft: "150px",
                  paddingRight: "150px",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  whiteSpace: "pre-line",
                }}
              >
                {HOME_PAGE_TITLE10}
              </HvTypography>
            </HvBox>
            <HvBox>
              <HvGrid container style={{ justifyContent: "center" }}>
                <HvGrid
                  item
                  sx={12}
                  xs={12}
                  xl={8}
                  lg={12}
                  sm={12}
                  md={12}
                  flexDirection={"row"}
                  display={"flex"}
                  justifyContent={"center"}
                  columnGap={5}
                >
                  {renderCategoryCards()}
                </HvGrid>
              </HvGrid>
            </HvBox>
          </HvStack>
          <HvContainer className={classes.footerContainer}>
            <HvStack
              direction="column"
              spacing="lg"
              className={classes.footerImage}
            >
              <HvBox
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "60px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <HvTypography
                  style={{
                    justifyContent: "Left",
                    alignItems: "center",
                    display: "flex",
                    paddingTop: "50px",
                    paddingLeft: "100px",
                    color: "white",
                  }}
                  variant="display"
                >
                  {HOME_PAGE_TITLE11}
                </HvTypography>
                <HvButton
                  style={{ alignSelf: "end" }}
                  variant="negativeSubtle"
                  className={classes.eGuide}
                  size="xl"
                  endIcon={<Next />}
                  onClick={() => {
                    setOpenContactUs(!openContactUs);
                  }}
                >
                  Contact Us
                </HvButton>
              </HvBox>
            </HvStack>
          </HvContainer>
        </HvBox>
      </HvBox>
    </HvBox>
  );
};

export default Login;
/*  Auth0 Code End */
