import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import {
  HvButton,
  HvDialog,
  HvDialogTitle,
  HvDialogContent,
  HvDialogActions,
  HvTab,
  HvTabs,
  HvTypography,
  HvContainer,
  HvStack,
  HvDrawer,
  HvEmptyState,
} from "@hitachivantara/uikit-react-core";
import {
  Stop,
  Pause,
  Open,
  Home,
  EditNote,
  DropRight,
  DropLeft,
  Time,
} from "@hitachivantara/uikit-react-icons";
import { classes } from "./style";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "redux/actions/commonActions";
import SplitPane from "components/SplitBar";
import "./styles.css";
import { useHistory } from "react-router";
import ReactPlayerLoader from "@brightcove/react-player-loader";
import _ from "lodash";
import FeedbackDrawer from "containers/newHome/FeebBackDrawer";
import LabService from "services/LabService";
import { buildQueryParams } from "utils/common";
import {
  CO_CREATION_LAB,
  LAB_AS_A_SERVICE,
  ON_DEMAND_LAB,
  OTHER_THAN_SAAS_LAB_TERMINATE_MESSAGE,
  PREP_LAB_ONDEMAND,
  SAAS_LAB,
  SAAS_LAB_TERMINATE_MESSAGE,
  VM_STATUS_NOTE,
} from "constants/labs";
import { EXTEND_VM_LAB, EXTEND_HARDWARE_LAB } from "constants/common";
import Loader from "./Loader";
import { labInfo } from "redux/actions/labActions";
import { showBanner } from "redux/actions/bannerActions";
import AppStreamEmbed from "./AppStreamEmbed";

const tabsName = [
  {
    index: 0,
    name: "Text Instructions",
  },
  {
    index: 1,
    name: "Video Instructions",
  },
];

export const SplitView = ({
  url,
  terminateLab,
  disconnectLab,
  labName,
  instruction,
  videoInstruction,
  labId,
  extendLabTime,
  extendedLabTime,
  scPortalKey,
  allowHardWareExtenstion,
  showHarWareBanner,
  session_id,
}) => {
  const [open, setOpen] = useState(false);
  const [openDisconnect, setOpenDisconnect] = useState(false);
  const [isResizing, setIsResizing] = useState(true);
  const [instructionsOpenedInTab, setInstructionsOpenedInTab] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [openedTabs, setOpenedTabs] = useState([]);
  const [openFeedBackDrawer, setOpenFeedbackDrawer] = useState(false);
  const [vmStatus, setVmStatus] = useState("busy");
  const [progressBarValue, setProgressBarValue] = useState(10);
  const [progressBarStatus, setProgressBarStatus] = useState("inProgress");
  const [remainingTime, setRemainingTime] = useState();
  const [progressBarStatusMsg, setProgressBarStatusMsg] =
    useState(VM_STATUS_NOTE);
  const [showVirtualBanner, setShowVirtualBanner] = useState(false);
  const [extendHardware, setExtendHardware] = useState(showHarWareBanner);
  const [labRemainingTime, setLabRemainigTime] = useState(null);
  const [isSAASSessionValid, setISSAASSessionValid] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const [showAtLabEnd, setShowAtLabEnd] = useState(false);
  const authWindowRef = useRef(null);
  const clearSightintervalIdRef = useRef(null);
  const authWindowIntervalRef = useRef(null);
  const extended_count = useSelector((state) =>
    !_.isNull(state.labReducer.labInfo?.extended_count)
      ? state.labReducer.labInfo?.extended_count
      : 0
  );
  const extended_time = useSelector((state) =>
    !_.isNull(state.labReducer.labInfo?.extended_minutes)
      ? state.labReducer.labInfo?.extended_minutes
      : 0
  );
  const virtualLabEndTime = useSelector((state) =>
    !_.isNull(state.labReducer.labInfo?.session_end_time)
      ? state.labReducer.labInfo?.session_end_time
      : 0
  );
  const labInfoObj = useSelector((state) => state.labReducer.labInfo);
  const schedule_labEndTime = useSelector((state) =>
    !_.isNull(state.labReducer.labInfo?.schedule_to)
      ? state.labReducer.labInfo?.schedule_to
      : ""
  );
  const labType = useSelector((state) => state.labReducer.labInfo?.lab_type);
  const provider = useSelector((state) => state.labReducer.labInfo?.provider);
  const [launchTime, setLaunchTime] = useState(null);
  const [scheduledLabEndTime, setScheduledLabEndTime] = useState(null);
  const [labUrl, setLabUrl] = useState();
  const [vmKey, setVmKey] = useState(scPortalKey);
  const [openDrawer, setOpenDrawer] = useState(false);
  const instructionRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const totalDelaySetRef = useRef(false);
  const labServiceObj = new LabService();
  const videoArray =
    !_.isNull(videoInstruction) && !_.isEmpty(videoInstruction)
      ? videoInstruction?.split(",").map((value) => value.trim())
      : [];
  const envId = useSelector((state) => state.commonReducer.environment_id);
  const environment_id = envId
    ? envId
    : sessionStorage.getItem("environmentId");
  const machineStatusRef = useRef();
  const activePollingRef = useRef(null);

  sessionStorage.setItem("Instruction", instruction);
  sessionStorage.setItem("Video", JSON.stringify(videoArray));
  sessionStorage.setItem("provider", provider);
  sessionStorage.setItem("sessionId", session_id);
  sessionStorage.setItem("labId", labId);
  sessionStorage.setItem("labType", labType);

  const handleOnLoad = () => {
    dispatch(showLoader(false));
  };

  const openTerminateDialog = () => {
    setOpen(true);
  };

  const openDisconnectDialog = () => {
    setOpenDisconnect(true);
  };
  const stopResizing = () => {
    setIsResizing(false);
  };

  const startResizing = () => {
    setIsResizing(true);
  };

  const SideBar = () => {
    return (
      <div id="sidebar-root">
        {openDrawer === false ? (
          <DropLeft
            style={{
              position: "absolute",
              right: "0%",
              top: "50%",
              zIndex: 1200,
            }}
            className={classes.drawerButton}
            onClick={() => {
              setOpenDrawer(true);
            }}
            size="xs"
          />
        ) : (
          <DropRight
            style={{
              position: instructionsOpenedInTab ? "fixed" : "absolute",
              right: "70px",
              top: "40%",
              zIndex: 1200,
            }}
            className={classes.drawerButton}
            onClick={() => {
              setOpenDrawer(false);
            }}
            size="xs"
          />
        )}
        <HvDrawer
          variant="persistent"
          PaperProps={{
            className: instructionsOpenedInTab
              ? classes.drawerStyle
              : classes.splitViewDrawer,
            component: "aside",
          }}
          open={openDrawer}
          anchor="right"
        >
          {labType === CO_CREATION_LAB ? (
            <HvStack divider style={{ paddingTop: "5px" }}>
              <Home
                className={classes.iconButton}
                onClick={() => {
                  disconnectLab();
                  closeAllTabs();
                  history.push("/labs");
                }}
                size={"xs"}
                title="Home"
              />
              <Stop
                onClick={openTerminateDialog}
                className={classes.iconButton}
                size={"xs"}
                title="Terminate"
              />
              <Pause
                onClick={openDisconnectDialog}
                className={classes.iconButton}
                size={"xs"}
                title="Disconnect"
              />

              <Open
                className={classes.iconButton}
                onClick={openInNewTab}
                size={"xs"}
                title="Open Instruction in Tab"
              />
              <EditNote
                onClick={() => {
                  setOpenFeedbackDrawer(!openFeedBackDrawer);
                }}
                className={classes.iconButton}
                size={"xs"}
                title="Feedback"
              />

              {labRemainingTime > 0 && vmStatus === "running" ? (
                <HvButton
                  variant="secondarySubtle"
                  style={{
                    minWidth: "50px",
                    padding: 0,
                    minHeight: "32px",
                    margin: "5px 10px",
                    backgroundColor: "var(--toast-white)",
                  }}
                >
                  {formatTime(labRemainingTime)}
                </HvButton>
              ) : (
                <></>
              )}
              {/* {<HvButton variant="secondarySubtle" style={{ minWidth: 32, padding: 0, margin: '5px 10px', }} onClick={() => { extendLabTime() }} title="Extend Lab"><Time className={classes.iconButton} /></HvButton>} */}
            </HvStack>
          ) : (
            <HvStack divider style={{ paddingTop: "5px" }}>
              <Home
                className={classes.iconButton}
                onClick={() => {
                  disconnectLab();
                  closeAllTabs();
                  history.push("/labs");
                }}
                size={"xs"}
                title="Home"
              />
              <Stop
                onClick={openTerminateDialog}
                className={classes.iconButton}
                size={"xs"}
                title="Terminate"
              />
              <Open
                className={classes.iconButton}
                onClick={openInNewTab}
                size={"xs"}
                title="Open Instruction in new tab"
              />
              <EditNote
                onClick={() => {
                  setOpenFeedbackDrawer(!openFeedBackDrawer);
                }}
                className={classes.iconButton}
                size={"xs"}
                title="Feedback"
              />

              {/* {<Time className={classes.iconButton} onClick={() => { extendLabTime() }} size={"xs"} title="Extend Lab" />} */}
              {labRemainingTime > 0 && vmStatus === "running" ? (
                <HvButton
                  variant="secondarySubtle"
                  style={{
                    minWidth: "50px",
                    padding: 0,
                    minHeight: "32px",
                    margin: "5px 10px",
                    backgroundColor: "var(--toast-white)",
                  }}
                >
                  {formatTime(labRemainingTime)}
                </HvButton>
              ) : (
                <></>
              )}
            </HvStack>
          )}
        </HvDrawer>
      </div>
    );
  };



  const openInNewTab = () => {
    const newTab = window.open("", "_blank");
    const path = "/instructionInTab"; // Adjust the path as needed

    if (newTab) {
      newTab.location.href = path;
      newTab.document.close();
      setOpenedTabs([...openedTabs, newTab]);
      setInstructionsOpenedInTab(true); // Update state
      setIsResizing(false);
    } else {
      console.error("Failed to open new tab. Please allow pop-ups.");
    }
  };


  const closeAllTabs = () => {
    openedTabs?.forEach((tab) => tab.close());
    setOpenedTabs([]);
  };
  const closeTabsAndTerminate = () => {
    if (!authWindowRef.current?.closed) {
      authWindowRef.current?.close();
      clearInterval(clearSightintervalIdRef.current);
      window.removeEventListener("message", handleMessage);
    }
    setOpen(false);
    closeAllTabs();
    terminateLab();
    sessionStorage.removeItem("Instruction");
    sessionStorage.removeItem("Video");
  };

  const closeTabsAndDisconnect = () => {
    setOpenDisconnect(false);
    closeAllTabs();
    disconnectLab();
    sessionStorage.removeItem("Instruction");
    sessionStorage.removeItem("Video");
  };

  const handleTabChange = (e, newValue) => setTabIndex(newValue);

  const renderInstruction = (instruction) => (
    <HvContainer
      className="custom-content"
      style={{ paddingLeft: "80px", paddingTop: "10px" }}
    >
      {_.isNull(instruction) ||
        _.isEmpty(instruction) ||
        _.isUndefined(instruction) ? (
        <HvTypography variant="selectedNavText">
          {" "}
          No Text Instructions.
        </HvTypography>
      ) : (
        <div ref={instructionRef} style={{ overflow: "scroll", width: "100%" }}>
          <object
            data={instruction}
            aria-label="Url"
            type="text/html"
            width={"100%"}
            height={window.screen.height}
            onLoad={handleOnLoad}
          />
        </div>
      )}
    </HvContainer>
  );

  const onFailure = () => console.log("player error");
  const onSuccess = () => console.log("player success");

  const renderVideo = (videoArray) => {
    if (videoArray?.length > 0) {
      return videoArray?.map((item) => {
        let urlParts = item?.split("/");
        const accountId = urlParts[3];
        const playerId = urlParts[4]?.split("_")[0];
        const videoId = urlParts[5]?.split("?videoId=")[1];
        if (videoId && accountId) {
          return (
            <>
              <HvContainer
                className="custom-content"
                style={{ paddingLeft: "80px", paddingTop: "10px" }}
              >
                <ReactPlayerLoader
                  accountId={accountId}
                  videoId={videoId}
                  playerId={playerId}
                  onFailure={onFailure}
                  onSuccess={onSuccess}
                  attrs={{
                    responsive: true,
                    autoplay: true,
                    muted: true,
                  }}
                  className={classes.videoPlayer}
                />
              </HvContainer>
            </>
          );
        }
      });
    } else {
      return (
        <>
          <HvTypography
            variant="selectedNavText"
            style={{
              alignSelf: "center",
              paddingLeft: "80px",
              paddingTop: "20px",
            }}
          >
            No Video Instructions.
          </HvTypography>
        </>
      );
    }
  };

  const handleFeedbacKState = (value) => {
    setOpenFeedbackDrawer(value);
    if (showAtLabEnd) {
      history.push("/labs");
    }
  };

  const setProgressBarPercentage = (stage) => {
    switch (stage) {
      case 0:
        setProgressBarValue(30);
        setProgressBarStatusMsg(
          "Please wait while the little elves create your lab."
        );
        break;
      case 1:
        setProgressBarValue(60);
        setProgressBarStatusMsg(
          "The server is powered by a lemon and two electrodes."
        );
        break;
      case 2:
        setProgressBarValue(90);
        setProgressBarStatusMsg(
          "Please wait while a larger hardware vendor in Santa Clara takes over the world"
        );
        break;
      case "completed":
        setProgressBarValue(95);
        setProgressBarStatusMsg(
          "Please wait while a larger hardware vendor in Santa Clara takes over the world"
        );
        break;
      case "running":
        setProgressBarValue(100);
        setProgressBarStatusMsg(
          "Your lab is ready, and will be launched shortly."
        );
        break;
      default:
        setProgressBarValue((prev) => prev + 1);
        break;
    }
  };
  const getVmStatus = () => {
    try {
      let params = {};
      params["environment_id"] = environment_id;
      labServiceObj.getVmStatus(buildQueryParams(params)).then((response) => {
        if (response) {
          if (!_.isNull(response.data?.status)) {
            setVmStatus(response?.data.status);
            setProgressBarPercentage(response?.data?.current_stage_index);

            if (!totalDelaySetRef.current) {
              const totalDelay = response?.data.steps?.reduce(
                (sum, step) => sum + step.delay_after_finish_seconds,
                0
              );
              setRemainingTime(totalDelay);
              totalDelaySetRef.current = true;
            }
            if (response.data.status === "running") {
              setProgressBarStatus("completed");
              setProgressBarPercentage(response.data.status);
              clearInterval(machineStatusRef.current);
            }
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleLaunchLab = () => {
    setLaunchTime(new Date().getTime());
  };

  useEffect(() => {
    let params = {};
    params["lab"] = labId;
    params["timezone"] = encodeURIComponent(
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    params["pre_extend"] = true;
    params["session_id"] = session_id;
    if (labType === LAB_AS_A_SERVICE && allowHardWareExtenstion) {
      labServiceObj
        .extendHardwareLab(buildQueryParams(params))
        .then((response) => {
          if (
            !_.isEmpty(response) &&
            response?.data?.pre_extended_status === true
          ) {
            let labParams = {};
            labParams["lab"] = labId;
            labServiceObj
              .getCurrentScheduleLab(buildQueryParams(labParams))
              .then((response) => {
                setScheduledLabEndTime(
                  response.data?.current_slot[0]?.schedule_to
                );
                dispatch(
                  labInfo({
                    ...labInfoObj,
                    schedule_to: response.data?.current_slot[0]?.schedule_to,
                  })
                );
              })
              .catch((error) => {
                console.log(error);
                setScheduledLabEndTime("");
                setExtendHardware(false);
              });
          } else {
            console.log("lab already extended");
            setExtendHardware(false);
            setScheduledLabEndTime("");
          }
        })
        .catch((error) => {
          console.log("lab already extended");
          setExtendHardware(false);
          setScheduledLabEndTime("");
          dispatch(
            showBanner({
              payload: {
                showBanner: false,
                label: { message: error?.response?.data?.message },
                variant: "error",
              },
            })
          );
        });
    }
  }, [allowHardWareExtenstion]);

  useEffect(() => {
    setLabUrl(url);
    setVmKey(scPortalKey);
    setExtendHardware(showHarWareBanner);
  }, [
    url,
    extendedLabTime,
    vmKey,
    scPortalKey,
    showHarWareBanner,
    allowHardWareExtenstion,
  ]);

  useEffect(() => {
    let labEndTimer = "";
    if (
      vmStatus === "running" &&
      (extended_count > 0 || labType === LAB_AS_A_SERVICE)
    ) {
      handleLaunchLab();
    }

    const clearStopAllowedExtension = () => {
      localStorage.clear("stopAllowedExtension");
    };

    const setTimer = (endTime) => {
      const delay = new Date(endTime)?.getTime() - new Date().getTime();
      if (delay > 0) {
        labEndTimer = setTimeout(clearStopAllowedExtension, delay);
      }
    };

    if (labType === LAB_AS_A_SERVICE && !_.isEmpty(schedule_labEndTime)) {
      setTimer(schedule_labEndTime);
    }

    if (labType === PREP_LAB_ONDEMAND || labType === ON_DEMAND_LAB) {
      setTimer(virtualLabEndTime);
    }

    return () => clearTimeout(labEndTimer);
  }, [virtualLabEndTime, scheduledLabEndTime, vmStatus]);

  useEffect(() => {
    if (!_.isEmpty(scheduledLabEndTime)) {
      const endTime = new Date(scheduledLabEndTime);
      const bannerTime = new Date(endTime.getTime() - 10 * 60000); // 10 minutes before end time
      const currentTime = new Date().getTime();
      const timeUntilBanner = bannerTime.getTime() - currentTime;
      if (timeUntilBanner > 0) {
        console.log(
          `Time to check extened slot ${bannerTime.toLocaleTimeString()}`
        );
        const timer = setTimeout(() => {
          extendLabTime();
        }, timeUntilBanner);

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
      } else {
        console.log(
          "Banner will show immediately as less than 10 minutes remain"
        );
        setExtendHardware(true); // If less than 10 minutes remain, show the banner immediately
      }
    } else {
      if (
        launchTime !== null &&
        vmStatus === "running" &&
        (labType === ON_DEMAND_LAB || labType === PREP_LAB_ONDEMAND) &&
        virtualLabEndTime !== null
      ) {
        const endTime = new Date(virtualLabEndTime);
        const bannerTime = new Date(endTime.getTime() - 10 * 60000); // 10 minutes before end time
        const currentTime = new Date().getTime();
        const timeUntilBanner = bannerTime.getTime() - currentTime;
        if (timeUntilBanner > 0) {
          console.log(`Banner will show at ${bannerTime.toLocaleTimeString()}`);
          const timer = setTimeout(() => {
            setShowVirtualBanner(true);
          }, timeUntilBanner);
          return () => clearTimeout(timer); // Cleanup the timer on component unmount
        } else {
          setShowVirtualBanner(true); // If less than 10 minutes remain, show the banner immediately
        }
      }
    }
  }, [vmStatus, launchTime, scheduledLabEndTime]);

  useEffect(() => {
    if (labType === LAB_AS_A_SERVICE && provider === "AWS" && !_.isEmpty(labUrl)) {
      setVmStatus("running");
    } else {
      machineStatusRef.current = setInterval(
        () => getVmStatus(machineStatusRef),
        5000
      );
    }

    return () => clearInterval(machineStatusRef.current);
  }, [labUrl]);

  const extendLab = () => {
    setShowVirtualBanner(!showVirtualBanner);
    extendLabTime();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    let timer;
    if (labRemainingTime > 0) {
      timer = setInterval(() => {
        setLabRemainigTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    if (labRemainingTime === 0) {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: { message: "Lab session has expired." },
            variant: "error",
            showAtLabEnd: true,
          },
        })
      );
      clearInterval(timer);
      clearInterval(activePollingRef.current);
      closeAllTabs();
      setVmStatus("busy");
      setShowAtLabEnd(true);
      setOpenFeedbackDrawer(true);
      setExtendHardware(false);
      setShowVirtualBanner(false);
    }
    return () => clearInterval(timer);
  }, [labRemainingTime]);

  const userActivePollingApi = () => {
    let params = {};
    try {
      dispatch(showLoader(false));
      params["session_id"] = session_id;

      labServiceObj
        .checkSessionActive(buildQueryParams(params))
        .then((response) => {
          if (response) {
            if (
              !_.isNull(
                response?.data?.runtime_remaining && _.isNull(labRemainingTime)
              )
            ) {
              !showAtLabEnd
                ? setLabRemainigTime(response?.data?.runtime_remaining)
                : setLabRemainigTime(0);
            }
          }
          dispatch(showLoader(false));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (vmStatus === "running") {
      userActivePollingApi();
      activePollingRef.current = setInterval(() => {
        userActivePollingApi();
      }, 60000);

      return () => clearInterval(activePollingRef.current);
    }
  }, [vmStatus, vmKey]);

  const handleMessage = (event) => {
    // Validate the origin of the message
    console.log("listener called");
    const allowedOrigins = [
      "https://clearsight.hitachivantara-dev.com",
      "https://clearsight.hitachivantara-uat.com",
      "https://clearsight2.hitachivantara-uat.com",
      "https://clearsight.hitachivantara.com",
      "https://labs.hitachivantara-dev.com",
      "https://labs.hitachivantara-uat.com",
      "https://labs.hitachivantara.com",
      "https://auth-stage.hitachivantara.com/samlp/6b8NjgQMr2K2ES6XoPACs3dKJtNXYXwa?RelayState=deepLink=%2Fcustomer-demo",
    ];
    if (!allowedOrigins.includes(event.origin)) {
      console.warn("Received message from untrusted origin:", event.origin);
      return;
    }
    console.log("event data", event.data)
    if(event.data === "redirected"){
      openClearSightWindow();
      setVmStatus("running");
      window.removeEventListener("message", handleMessage);
      clearInterval(authWindowIntervalRef.current)
    }
    if (event.data === "validated") {
      setISSAASSessionValid(!isSAASSessionValid);
      window.removeEventListener("message", handleMessage);
      setVmStatus("running");
      authWindowRef.current?.close();
      authWindowRef.current = null;
      clearInterval(authWindowIntervalRef.current);
      authWindowIntervalRef.current = null;
    }
  };

  const openClearSightWindow = () => {
    dispatch(showLoader(false));
    let topsss = window.screen.height - 1;
    let left = window.screen.width - 1;

    if (isSAASSessionValid === false && labUrl) {
      if (authWindowRef.current) {
        authWindowRef.current.close();
        authWindowRef.current = null;
      }
      authWindowRef.current = window.open(
        labUrl, // Auth URL
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=" + topsss + ",left=" + left + ",width=1,height=2"
      );
      window.addEventListener("message", handleMessage);
      setOpenWindow(true);
    }
  };

  const monitorAuthWindowWithPing = () => {
    authWindowIntervalRef.current = setInterval(() => {
      try {
        // Check if the window is still open
        console.log("Checking auth window status...", authWindowRef.current);
        if (isSAASSessionValid) {
          clearInterval(authWindowIntervalRef.current)
        }
        if (
          authWindowRef.current &&
          authWindowRef.current?.location?.href.includes("deepLink")
        ) {
          console.log("Auth window closed by user.");
          window.removeEventListener("message", handleMessage);
          clearInterval(authWindowIntervalRef.current);
          openClearSightWindow();
        } else {
          // Try accessing a property of the window (will throw error if cross-origin)
          if (!_.isEmpty(authWindowRef.current?.location?.href)) {
            console.log("Auth window is still open and accessible.");
            setVmStatus("running");
            clearInterval(authWindowIntervalRef.current);
            openClearSightWindow();
            if (authWindowRef.current?.location?.href.includes("deepLink")) {
              console.log(
                "Auth window is on the deepLink page. Closing the window."
              );
              window.removeEventListener("message", handleMessage);
              clearInterval(authWindowIntervalRef.current);
             
            }
          }
        }
      } catch (error) {
        console.warn(
          "Unable to access auth window. It may have navigated away or is on a different origin."
        );
      }
    }, 1000);
  }; // Check every second

  useEffect(() => {
    if (
      labType === SAAS_LAB &&
      !_.isEmpty(labUrl) &&
      openedTabs.length === 0 &&
      openWindow === false &&
      provider !== "AWS"
    ) {
      dispatch(showLoader(true));
      openClearSightWindow();
      monitorAuthWindowWithPing()

    }
  }, [labUrl, isSAASSessionValid, openedTabs]);

  return (
    <div key={vmKey}>
      {
        <>
          {
            instructionsOpenedInTab ?
              <div style={{ display: "flex", width: "100%", height: "100%" }}>
                <SideBar />
                {vmStatus === "running" ?
                  labType === LAB_AS_A_SERVICE && provider === "AWS" ? <AppStreamEmbed sessionURL={labUrl} openedInSeparateTab={instructionsOpenedInTab} /> : <object
                    data={labUrl?.replace("/v2/vms/", "/vms/")}
                    width={window.screen.width}
                    height={window.screen.height}
                    className={classes.skytapScreen}
                  />
                  : !showAtLabEnd ?
                    <Loader
                      progressBarValue={progressBarValue}
                      progressBarStatusMsg={progressBarStatusMsg}
                      progressBarTime={remainingTime}
                      progressBarStatus={progressBarStatus}
                    />
                    :
                    <HvEmptyState title="Lab Session Ended."></HvEmptyState>
                }
              </div>
              :
              <>
                <SplitPane
                  split="vertical"
                  minSize={0}
                  maxSize={-100}
                  defaultSize={"50%"}
                  allowResize={isResizing}
                  onDragFinished={stopResizing}
                  onResizerClick={startResizing}
                  step={2}
                  primary="second"
                >
                  {/* pane1 */}
                  <div onMouseEnter={stopResizing} style={{ height: "100%" }} >
                    {vmStatus === "running" ?
                      labType === LAB_AS_A_SERVICE && provider === "AWS" ? <AppStreamEmbed sessionURL={labUrl} openedInSeparateTab={instructionsOpenedInTab} /> :
                        <object
                          data={labUrl?.replace("/v2/vms/", "/vms/")}
                          type="text/html"
                          aria-label="Url"
                          width={"100%"}
                          height={"100%"}
                          className={classes.skytapScreen} />
                      : !showAtLabEnd ?
                        <Loader
                          progressBarValue={progressBarValue}
                          progressBarStatusMsg={progressBarStatusMsg}
                          progressBarTime={remainingTime}
                          progressBarStatus={progressBarStatus}
                        />
                        :
                        <HvEmptyState title="Lab Session Ended."></HvEmptyState>
                    }
                    <SideBar />
                  </div>
                  {/* pane2 */}

                  <div
                    style={{ height: window.screen.height, overflow: "auto" }}
                  >
                    <div onMouseEnter={stopResizing} onChange={stopResizing}>
                      <HvTypography
                        variant="title2"
                        style={{ paddingLeft: "80px", paddingTop: "25px" }}
                      >
                        {labName}
                      </HvTypography>
                      <HvTabs
                        id="tabs-main"
                        value={tabIndex}
                        className={classes.tabs}
                        style={{ paddingLeft: "80px", paddingTop: "10px" }}
                        onChange={handleTabChange}
                      >
                        {tabsName.map((tab) => (
                          <HvTab
                            id={tab.index}
                            label={tab.name}
                            value={tab.index}
                            onChange={handleTabChange}
                          />
                        ))}
                      </HvTabs>
                      {tabIndex === 0 && renderInstruction(instruction)}

                      {tabIndex === 1 && renderVideo(videoArray)}
                    </div>
                  </div>

                </SplitPane>
              </>
          }
        </>
      }

      {open ? (
        <div>
          <HvDialog
            disableBackdropClick
            open={open}
            onClose={() => setOpen(false)}
            id="terminate"
            classes={{
              closeButton: classes.closeButton,
            }}
          >
            <HvDialogTitle variant="warning">Terminate Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              {labType === SAAS_LAB
                ? SAAS_LAB_TERMINATE_MESSAGE
                : OTHER_THAN_SAAS_LAB_TERMINATE_MESSAGE}
            </HvDialogContent>
            <HvDialogActions>
              <HvButton
                id="apply"
                onClick={closeTabsAndTerminate}
                variant="primaryGhost"
              >
                Yes
              </HvButton>
              <HvButton
                id="cancel"
                variant="primaryGhost"
                onClick={() => setOpen(false)}
              >
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div>
      ) : (
        <></>
      )}
      {openDisconnect ? (
        <div>
          <HvDialog
            disableBackdropClick={true}
            open={openDisconnect}
            id="disconnect"
            classes={{
              closeButton: classes.closeButton,
            }}
          >
            <HvDialogTitle variant="warning">Disconnect Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              Disconnect lab will pause the Lab.You can access it from Running
              Labs section.
            </HvDialogContent>
            <HvDialogActions>
              <HvButton
                id="apply"
                onClick={closeTabsAndDisconnect}
                variant="primaryGhost"
              >
                Yes
              </HvButton>
              <HvButton
                id="cancel"
                variant="primaryGhost"
                onClick={() => setOpenDisconnect(false)}
              >
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div>
      ) : (
        <></>
      )}
      {openFeedBackDrawer ? (
        <div>
          <FeedbackDrawer
            labId={labId}
            openDrawer={openFeedBackDrawer}
            handleDrawerState={handleFeedbacKState}
            showAtLabEnd={showAtLabEnd}
            terminateLab={terminateLab}
          />
        </div>
      ) : (
        <></>
      )}
      {showVirtualBanner ? (
        <div>
          <HvDialog
            disableBackdropClick={true}
            open={showVirtualBanner}
            id="ExtendLab"
            classes={{
              closeButton: classes.closeButton,
            }}
          >
            <HvDialogTitle variant="warning">Extend Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              {EXTEND_VM_LAB(extended_time)}
            </HvDialogContent>
            <HvDialogActions>
              <HvTypography variant="body">
                Popup will close in {formatTime(labRemainingTime)}
              </HvTypography>
              <HvButton id="apply" onClick={extendLab} variant="primaryGhost">
                Yes
              </HvButton>
              <HvButton
                id="cancel"
                variant="primaryGhost"
                onClick={() => {
                  setShowVirtualBanner(false);
                  setLaunchTime(null);
                }}
              >
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div>
      ) : (
        <></>
      )}
      {extendHardware ? (
        <div>
          <HvDialog
            disableBackdropClick={true}
            open={extendHardware}
            id="ExtendLab"
            classes={{
              closeButton: classes.closeButton,
            }}
          >
            <HvDialogTitle variant="warning">Extend Lab?</HvDialogTitle>
            <HvDialogContent indentContent>
              {EXTEND_HARDWARE_LAB}
            </HvDialogContent>
            <HvDialogActions>
              <HvTypography variant="body">
                Popup will close in {formatTime(labRemainingTime)}
              </HvTypography>
              <HvButton
                id="apply"
                onClick={() => {
                  setExtendHardware(false);
                  extendLabTime(true);
                  setScheduledLabEndTime("");
                }}
                variant="primaryGhost"
              >
                Yes
              </HvButton>
              <HvButton
                id="cancel"
                variant="primaryGhost"
                onClick={() => {
                  setExtendHardware(false);
                  setLaunchTime(null);
                  setScheduledLabEndTime("");
                }}
              >
                No
              </HvButton>
            </HvDialogActions>
          </HvDialog>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
