import {
  HvCardContent,
  HvTag,
  HvTypography,
  HvOverflowTooltip,
  HvBox,
} from "@hitachivantara/uikit-react-core";
import { htmlToText } from "html-to-text";
import React from "react";
import { classes } from "./style";
import { Tag, Layers, Host, Versions } from "@hitachivantara/uikit-react-icons";
import { HvLoading } from "@hitachivantara/uikit-react-core";
import _ from "lodash";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router";
import { PHYSICAL_HARDWARE, VIRTUAL_HARDWARE } from "constants/common";
import {
  SAAS_LAB,
  LAB_AS_A_SERVICE,
  ON_DEMAND_LAB,
  PREP_LAB_ONDEMAND,
} from "constants/labs";

const CardContent = (props) => {
  const { lab, runLab } = props;

  const redirect = (
    url,
    labId,
    enviroment_id,
    used_time,
    extended_count,
    url_end_time,
    extended_minutes,
    co_creation_lab,
    session_end_time,
    provider,
    lab_type
  ) => {
    props.history.push({
      pathname: url,
      state: {
        labId: labId,
        environment_id: enviroment_id,
        used_time: used_time,
        extended_count: extended_count,
        url_end_time: url_end_time,
        extended_minutes: extended_minutes,
        co_creation_lab: co_creation_lab,
        session_end_time: session_end_time,
        provider: provider,
        lab_type: lab_type,
      },
    });
  };

  const renderLabTypeIcon = (tag_description) => {
    switch (tag_description) {
      case ON_DEMAND_LAB:
        return (
          <>
            <HvBox style={{ display: "flex" }}>
              <Host />
              <HvTypography style={{ alignSelf: "center" }}>
                {VIRTUAL_HARDWARE}
              </HvTypography>
            </HvBox>
          </>
        );
      case PREP_LAB_ONDEMAND:
        return (
          <>
            <HvBox style={{ display: "flex" }}>
              <Host />
              <HvTypography style={{ alignSelf: "center" }}>
                {VIRTUAL_HARDWARE}
              </HvTypography>
            </HvBox>
          </>
        );
      case LAB_AS_A_SERVICE:
        return (
          <>
            <HvBox style={{ display: "flex" }}>
              <Host />
              <HvTypography style={{ alignSelf: "center" }}>
                {PHYSICAL_HARDWARE}
              </HvTypography>
            </HvBox>
          </>
        );
      case SAAS_LAB:
        return (
          <>
            <HvBox style={{ display: "flex" }}>
              <Host />
              <HvTypography style={{ alignSelf: "center" }}>
                {SAAS_LAB}
              </HvTypography>
            </HvBox>
          </>
        );
      default:
        return (
          <>
            <HvBox style={{ display: "flex" }}>
              <Host size="XS" />
              <HvTypography variant="caption1" style={{ alignSelf: "center" }}>
                NA
              </HvTypography>
            </HvBox>
          </>
        );
    }
  };

  const truncateHTML = (html, maxLength) => {
    // Convert HTML to plain text
    const plainText = htmlToText(html, {
      wordwrap: false,
      ignoreHref: true,
      ignoreImage: true,
    }).trim();

    // If text is already short enough, return as-is
    if (plainText.length <= maxLength)
      return plainText.replace(/&[a-zA-Z]*$/, "");

    // Find the last space before maxLength to avoid cutting words
    let truncateIndex = plainText.lastIndexOf(" ", maxLength);
    if (truncateIndex === -1) truncateIndex = maxLength;

    // Extract the truncated text
    const truncatedText = plainText.substring(0, truncateIndex) + "...";

    return truncatedText;
  };
  return (
    <HvCardContent
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100%",
        cursor: "default",
      }}
    >
      {runLab !== lab.id ? (
        <HvBox
          style={{ width: "-webkit-fill-available", height: "auto", gap: 2 }}
        >
          <HvBox style={{ width: "-webkit-fill-available" }}>
            <HvTypography className={classes.description} variant="normalText">
              {truncateHTML(lab?.description, 100)}
            </HvTypography>
          </HvBox>
          <HvTypography
            variant="link"
            className={classes.knowMore}
            onClick={() => {
              redirect(
                "/labdetailview",
                lab?.lab ? lab?.lab : lab?.id,
                lab?.enviroment_id,
                lab?.used_time,
                lab?.extended_count,
                lab?.url_end_time,
                lab?.extended_minutes,
                lab?.co_creation_lab,
                lab?.session_end_time,
                lab?.provider,
                lab?.lab_type
              );
            }}
          >
            Details
          </HvTypography>
          <HvBox style={{ display: "flex" }}>
            <HvBox style={{ display: "flex", columnGap: "3px" }} width="100%">
              <Layers />
              <HvTypography style={{ alignSelf: "center" }}>
                {lab?.categories && lab.categories.length > 0
                  ? lab.categories.length > 1
                    ? `${lab.categories[0]} +${lab.categories.length - 1}`
                    : lab.categories[0]
                  : "NA"}
              </HvTypography>
            </HvBox>
          </HvBox>
          <HvBox style={{ display: "flex" }} width={"100%"}>
            {lab.labtags && lab.labtags.length > 0 ? (
              <>
                <HvBox style={{ display: "flex", columnGap: "1px" }}>
                  <Tag />
                  <HvBox
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "flex-start",
                    }}
                  >
                    {lab.labtags.length > 4
                      ? lab.labtags.slice(0, 3).map((t1) => {
                          return (
                            <>
                              <HvTag
                                className={classes.tagLabel}
                                label={
                                  t1.length > 6 ? (
                                    <HvOverflowTooltip
                                      data={t1}
                                    ></HvOverflowTooltip>
                                  ) : (
                                    t1
                                  )
                                }
                                type="categorical"
                                color={_.sample([
                                  "cat2",
                                  "cat3",
                                  "cat4",
                                  "cat5",
                                  "cat6",
                                ])}
                                style={{ alignSelf: "center" }}
                              />
                            </>
                          );
                        })
                      : lab.labtags.map((t1) => {
                          return (
                            <>
                              <HvTag
                                className={classes.tagLabel}
                                label={
                                  t1.length > 6 ? (
                                    <HvOverflowTooltip
                                      data={t1}
                                    ></HvOverflowTooltip>
                                  ) : (
                                    t1
                                  )
                                }
                                type="categorical"
                                color={_.sample([
                                  "cat2",
                                  "cat3",
                                  "cat4",
                                  "cat5",
                                  "cat6",
                                ])}
                                style={{ alignSelf: "center" }}
                              />
                            </>
                          );
                        })}
                  </HvBox>
                </HvBox>
              </>
            ) : (
              <></>
            )}
          </HvBox>
          <HvBox style={{ display: "flex", columnGap: "4px" }}>
            {renderLabTypeIcon(lab.tag_description)}
          </HvBox>
        </HvBox>
      ) : (
        <HvBox>
          <HvLoading small />
          <HvTypography style={{ paddingTop: 27 }}>
            Please wait as your lab is being configured
          </HvTypography>
        </HvBox>
      )}
    </HvCardContent>
  );
};

CardContent.propTypes = {
  history: PropTypes.any,
};
export default withRouter(CardContent);
