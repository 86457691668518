import {
  HvActionBar,
  HvButton,
  HvCard,
  HvCardHeader,
  HvTooltip,
  HvTypography,
  HvGrid,
  HvTag,
  HvLabel,
  HvBox,
  HvToggleButton,
} from "@hitachivantara/uikit-react-core";
import { Fragment, useState, useEffect } from "react";
import CardContent from "./CardContent";
import { classes } from "./style";
import {
  PlayVideoScreen,
  Calendar,
  Share,
  EditNote,
  Favorite,
  FavoriteSelected,
} from "@hitachivantara/uikit-react-icons";
import { LOGGED_IN_USER_INFO } from "constants/common";
import LabService from "services/LabService";
import {
  LANGUAGE_LOGIN_ERROR_LAB,
  LANGUAGE_GENERAL_SSO_ERROR,
  LAB_INFO,
} from "constants/login";
import { toast } from "react-toast";
import { useSelector } from "react-redux";
import {
  CO_CREATION_LAB,
  LAB_AS_A_SERVICE,
  LAB_ON_DEMAND_MESSAGE,
  LAB_SERVICE_MESSAGE,
  ON_DEMAND_LAB,
  PREP_LAB,
  PREP_LAB_ONDEMAND,
  SENDERS_NOTE,
} from "constants/labs";
import { useHistory, withRouter } from "react-router";
import { PropTypes } from "prop-types";
import { useDispatch } from "react-redux";
import { labEnvironmentId, showLoader } from "redux/actions/commonActions";
import {
  HvDialog,
  HvDialogTitle,
  HvDialogContent,
  HvDialogActions,
  HvInput,
  HvTextArea,
} from "@hitachivantara/uikit-react-core";
import { showBanner } from "redux/actions/bannerActions";
import FeedbackDrawer from "./FeebBackDrawer";
import { format } from "date-fns";
import _ from "lodash";
import { buildQueryParams } from "utils/common";
import AgreementDialog from "components/agreementDialog/AgreementDialog";
import { CloseXS } from "@hitachivantara/uikit-react-icons";
import { labInfo } from "redux/actions/labActions";
import moment from "moment";

const LabsCardComp = (props) => {
  const themeSelector = useSelector((state) => state.themeReducer.theme);
  const { labsData, selectedTabIndex, markFavorite, getScheduledLabData } =
    props;
  const [openFeedbackDrawer, setFeedbackDrawarOpen] = useState(false);
  const [copLabUrl, setCopyLabUrl] = useState(false);
  const [emailLabUrl, setEmailLabUrl] = useState("");
  const [runLab, setRunLab] = useState(props.runningLab === 0 ? false : true);
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [labId, setLabId] = useState();
  const [emailLabName, setEmailLabName] = useState("");
  const [toEmails, setToEmails] = useState("");
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);
  const [labDescription, setLabDescription] = useState("");
  const [emailNote, setEmailNote] = useState();
  const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState("");
  const [slotId, setSlotId] = useState();
  const history = useHistory();
  const dispatch = useDispatch();
  const labServiceObj = new LabService();
  const user = JSON.parse(sessionStorage.getItem("HALO-USER-INFO"));

  const handleFeedbacKState = (value) => {
    setFeedbackDrawarOpen(value);
  };

  const launchPrepLab = (lab_id, labName, acceptAggreement, labType) => {
    if (acceptAggreement) {
      const payload = { lab: lab_id };
      const urlParams = { lab_id: lab_id };
      let finalData = {};
      dispatch(showLoader(true));
      labServiceObj
        .activateLabUrl(payload)
        .then((response) => {
          if (response && response.url !== "" && response.url !== null) {
            let data = {
              url: response.url,
              labId: lab_id,
              labName: labName,
              id: response.id,
              environment_id: response?.enviroment_id,
              lab_type: labType,
            };
            dispatch(labEnvironmentId(response?.enviroment_id));
            labServiceObj
              .getInstruction(buildQueryParams(urlParams))
              .then((response) => {
                if (response) {
                  finalData = {
                    ...data,
                    instruction: response?.data.results[0]?.instruction_file,
                    video_instruction:
                      response?.data.results[0]?.video_instruction,
                  };
                }
                dispatch(showLoader(false));
                redirect("/scportal", finalData);
                setRunLab(true);
                const labData = [];
                labData.push(finalData);
                props.setAllLabs(false, labData);
              });
          }
        })
        .catch(() => {
          setRunLab(false);
          toast.error(LANGUAGE_GENERAL_SSO_ERROR);
        });
    }
  };

  const launchLab = (lab_id, labName, labType) => {
    const payload = { lab: lab_id };
    const urlParams = { lab_id: lab_id };
    let finalData = {};
    dispatch(showLoader(true));
    labServiceObj
      .activateLabUrl(payload)
      .then((response) => {
        if (response && response.url !== "" && response.url !== null) {
          let data = {
            url: response.url,
            labId: lab_id,
            labName: labName,
            id: response.id,
            environment_id: response?.enviroment_id,
            lab_type: labType ? labType : response?.lab_type,
            extended_count: response?.extended_count,
            extended_minutes: response?.extended_minutes,
          };
          dispatch(labEnvironmentId(response?.enviroment_id));
          sessionStorage.setItem("environmentId", response?.enviroment_id);
          dispatch(labInfo(response));
          localStorage.clear("stopAllowedExtension");
          labServiceObj
            .getInstruction(buildQueryParams(urlParams))
            .then((response) => {
              if (response) {
                finalData = {
                  ...data,
                  instruction: response?.data.results[0]?.instruction_file,
                  video_instruction:
                    response?.data.results[0]?.video_instruction,
                };
              }
              dispatch(showLoader(false));
              redirect("/scportal", finalData);
              setRunLab(true);
              const labData = [];
              labData.push(finalData);
              props.setAllLabs(false, labData);
            });
        }
      })
      .catch(() => {
        setRunLab(false);
        toast.error(LANGUAGE_GENERAL_SSO_ERROR);
      });
  };
  const fetchRedirectionUrlForLab = (lab_id, labName, lab_type) => {
    if (lab_type === PREP_LAB) {
      //setOpenAgreementDialog(false);
      launchLab(lab_id, labName, lab_type);
    } else {
      launchLab(lab_id, labName, lab_type);
    }
  };

  const configureLabAndRedirect = (
    lab_id,
    lab_name,
    portal_url,
    session,
    id,
    content,
    enviroment_id,
    lab_type,
    max_time,
    used_time,
    extended_count,
    url_end_time,
    extended_minutes,
    co_creation_lab,
    session_end_time,
    provider
  ) => {
    const data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
    const token = data ? JSON.parse(data) : {};
    const urlParams = { lab_id: lab_id };
    let finalData = {};
    if (token?.access_token) {
      if (selectedTabIndex === 1) {
        let data = {
          url: portal_url,
          labId: lab_id,
          name: lab_name,
          id: id,
          enviroment_id: enviroment_id,
          lab_type: lab_type,
          max_time: max_time,
          used_time: used_time,
          extended_count: extended_count,
          url_end_time: url_end_time,
          extended_minutes: extended_minutes,
          co_creation_lab: co_creation_lab,
          session_end_time: session_end_time,
          provider: provider,
        };
        showLoader(true);
        dispatch(labEnvironmentId(enviroment_id));
        sessionStorage.setItem("environmentId", enviroment_id);
        dispatch(labInfo(data));
        labServiceObj
          .getInstruction(buildQueryParams(urlParams))
          .then((response) => {
            if (response) {
              showLoader(false);
              finalData = {
                ...data,
                instruction: response?.data.results[0]?.instruction_file,
                video_instruction: response?.data.results[0]?.video_instruction,
              };
            }
            redirect("/scportal", finalData);
            const labData = [];
            labData.push(data);
          });
      } else if (selectedTabIndex === 0) {
        if (session && session.length > 0) {
          let data = {
            url: session[0].url,
            labId: lab_id,
            labName: lab_name,
            id: session[0].id,
            instruction: content,
          };
          const labData = [];
          labData.push(data);
          props.setAllLabs(false, labData);
        } else {
          setRunLab(true);
          fetchRedirectionUrlForLab(lab_id, lab_name, content);
        }
      }
    } else {
      toast.error(LANGUAGE_LOGIN_ERROR_LAB);
      history.push("/");
    }
  };

  useEffect(() => {
    setRunLab(props?.runningLab > 0 ? true : false);
  }, [props?.runningLab]);

  const redirect = (url, labData) => {
    props.history.push({
      pathname: url,
      state: {
        labDetail: labData,
      },
    });
  };

  const validateEmails = (emails) => {
    // Use a regular expression to validate email addresses.
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const emailList = emails.split(",").map((email) => email.trim());

    if (_.isEmpty(emails)) {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: { message: "To Field can not be empty" },
            variant: "error",
          },
        })
      );
      return false;
    }
    if (_.isEmpty(emailLabName)) {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: { message: "Subject can not be empty" },
            variant: "error",
          },
        })
      );
      return false;
    }
    if (
      _.isEmpty(emailLabUrl) ||
      _.isUndefined(emailNote) ||
      _.isEmpty(emailNote)
    ) {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: { message: SENDERS_NOTE },
            variant: "error",
          },
        })
      );
      return false;
    }
    if (emailList?.length > 100) {
      dispatch(
        showBanner({
          payload: {
            showBanner: true,
            label: { message: "Email list can not be greater than 100." },
            variant: "error",
          },
        })
      );
      return false;
    } else {
      for (const email of emailList) {
        if (!emailPattern.test(email)) {
          dispatch(
            showBanner({
              payload: {
                showBanner: true,
                label: { message: "Enter valid Email Address." },
                variant: "error",
              },
            })
          );
          return false;
        }
      }
    }

    return true;
  };

  const sendEmail = () => {
    const validateFields = validateEmails(toEmails);
    if (validateFields) {
      const emailPaylod = {
        subject: "HALO Lab :" + " " + emailLabName,
        body: emailLabUrl,
        to: toEmails,
        to_name: emailLabName,
        lab_id: labId,
        note: emailNote,
      };
      dispatch(showLoader(true));
      labServiceObj.sendMail(emailPaylod).then((response) => {
        setToEmails("");
        setEmailNote("");
        if (response) {
          dispatch(showLoader(false));
          setOpenEmailPopup(false);
          dispatch(
            showBanner({
              payload: {
                showBanner: true,
                label: { message: "Email sent successfully." },
                variant: "success",
              },
            })
          );
        }
      });
    }
  };

  const handleToChange = (e) => {
    const value = e.target.value;
    setToEmails(value);
  };

  function removeHtmlTags(inputString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(inputString, "text/html");
    return doc.body.textContent || "";
  }
  // const defaultEmailTemplate = 'Hello,\n\nPlease find the below lab details \n\n' + "Link :" + emailLabUrl + "\n\n" + "Overview :\n" + removeHtmlTags(labDescription) + '\n\n Regards,';
  const defaultEmailTemplate = `Hello,

  Please find the below lab details

  Link: ${emailLabUrl}

  Overview:
  ${removeHtmlTags(labDescription)}

  Regards,`;

  const dataUri = encodeURIComponent(defaultEmailTemplate);

  const setEmail = (lab) => {
    setCopyLabUrl(!copLabUrl);
    localStorage.removeItem("LabUrl");
    setEmailLabUrl(
      window.location.origin + "/labdetailview/?labId=" + lab?.id + " "
    );
    setEmailLabName(lab?.name);
    setOpenConfirmationPopup(!openConfirmationPopup);
    setLabDescription(lab?.description);
    setLabId(lab?.id);
    setToEmails("");
  };

  const cancelSlot = (id) => {
    setCancelDialog(!cancelDialog);
    dispatch(showLoader(true));
    labServiceObj
      .cancelSelectedSlot(id)
      .then((response) => {
        dispatch(showLoader(false));
        dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: { message: "Slot Cancelled." },
              variant: "success",
            },
          })
        );
        getScheduledLabData();
      })
      .catch((error) => {
        dispatch(
          showBanner({
            payload: {
              showBanner: true,
              label: { message: error?.message },
              variant: "error",
            },
          })
        );
      });
  };

  const renderLabButton = (lab) => {
    switch (lab?.tag_description) {
      case ON_DEMAND_LAB:
        return (
          <>
            <HvTooltip
              title={<HvTypography>{LAB_ON_DEMAND_MESSAGE}</HvTypography>}
            >
              {" "}
              <HvButton
                onClick={() => {
                  fetchRedirectionUrlForLab(lab?.id, lab?.name, lab?.lab_type);
                }}
                startIcon={<PlayVideoScreen color={"primary"} />}
                variant="primaryGhost"
                className={classes.hvButtonPrimary}
              >
                Run Lab
              </HvButton>
            </HvTooltip>
          </>
        );
      case PREP_LAB_ONDEMAND:
        return (
          <>
            <HvTooltip
              title={<HvTypography>{LAB_ON_DEMAND_MESSAGE}</HvTypography>}
            >
              {" "}
              <HvButton
                onClick={() => {
                  fetchRedirectionUrlForLab(lab?.id, lab?.name, lab?.lab_type);
                }}
                startIcon={<PlayVideoScreen color={"primary"} />}
                variant="primaryGhost"
                className={classes.hvButtonPrimary}
              >
                Run Lab
              </HvButton>
            </HvTooltip>
          </>
        );
      case LAB_AS_A_SERVICE:
        return (
          <>
            <HvTooltip
              title={<HvTypography>{LAB_SERVICE_MESSAGE}</HvTypography>}
            >
              {" "}
              <HvButton
                onClick={() => {
                  redirect("/labscheduler", lab);
                }}
                startIcon={<Calendar color={"primary"} />}
                variant="primaryGhost"
                className={classes.hvButtonPrimary}
              >
                Schedule Lab
              </HvButton>
            </HvTooltip>
          </>
        );
      default:
        return (
          <>
            <HvTooltip
              title={<HvTypography>{LAB_ON_DEMAND_MESSAGE}</HvTypography>}
            >
              {" "}
              <HvButton
                onClick={() => {
                  fetchRedirectionUrlForLab(lab?.id, lab?.name, lab?.lab_type);
                }}
                startIcon={<PlayVideoScreen color={"primary"} />}
                variant="primaryGhost"
                className={classes.hvButtonPrimary}
              >
                Run Lab
              </HvButton>
            </HvTooltip>
          </>
        );
    }
  };

  function convertTo12HourFormatSchedule(timeRange, time) {
    const formattedDateTime = time
      ? format(new Date(timeRange), "h:mm a")
      : format(new Date(timeRange), "MM/dd/yyyy");
    return formattedDateTime;
  }

  const renderSecondaryButton = (lab) => {
    switch (lab?.tag_description) {
      case ON_DEMAND_LAB:
        return (
          <HvBox>
            <HvTooltip
              title={<HvTypography>{"Share via Mail"}</HvTypography>}
              onMouseLeave={() => {
                setCopyLabUrl(false);
              }}
              onMouseEnter={() => {
                setCopyLabUrl(false);
              }}
            >
              <HvButton
                startIcon={
                  <Share
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEmail(lab);
                    }}
                  />
                }
                variant="secondaryGhost"
                className={classes.shareButtonGhost}
              ></HvButton>
            </HvTooltip>
            <HvTooltip title={<HvTypography>{"Feedback"}</HvTypography>}>
              <HvButton
                startIcon={<EditNote />}
                variant="secondaryGhost"
                onClick={() => {
                  setLabId(lab?.id);
                  setFeedbackDrawarOpen(!openFeedbackDrawer);
                }}
              ></HvButton>
            </HvTooltip>
          </HvBox>
        );

      case PREP_LAB_ONDEMAND:
        return (
          <HvBox display="flex" alignItems={"center"}>
            <HvTooltip title={<HvTypography>{"Feedback"}</HvTypography>}>
              <HvButton
                startIcon={<EditNote />}
                variant="secondaryGhost"
                onClick={() => {
                  setLabId(lab?.id);
                  setFeedbackDrawarOpen(!openFeedbackDrawer);
                }}
              ></HvButton>
            </HvTooltip>
          </HvBox>
        );
      case LAB_AS_A_SERVICE:
        return (
          <HvBox style={{ display: "flex" }}>
            <HvTooltip
              title={<HvTypography>{"Share via Mail"}</HvTypography>}
              onMouseLeave={() => {
                setCopyLabUrl(false);
              }}
              onMouseEnter={() => {
                setCopyLabUrl(false);
              }}
            >
              <HvButton
                startIcon={
                  <Share
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEmail(lab);
                    }}
                  />
                }
                variant="secondaryGhost"
                className={classes.shareButtonGhost}
              ></HvButton>
            </HvTooltip>
            <HvTooltip title={<HvTypography>{"Feedback"}</HvTypography>}>
              <HvButton
                startIcon={<EditNote />}
                variant="secondaryGhost"
                onClick={() => {
                  setLabId(lab?.id);
                  setFeedbackDrawarOpen(!openFeedbackDrawer);
                }}
              ></HvButton>
            </HvTooltip>
          </HvBox>
        );
      default:
        return (
          <HvBox display="flex" alignItems={"center"}>
            <HvTooltip
              title={<HvTypography>{"Share via Mail"}</HvTypography>}
              onMouseLeave={() => {
                setCopyLabUrl(false);
              }}
              onMouseEnter={() => {
                setCopyLabUrl(false);
              }}
            >
              <HvButton
                startIcon={
                  <Share
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setEmail(lab);
                    }}
                  />
                }
                variant="secondaryGhost"
                className={classes.shareButtonGhost}
              ></HvButton>
            </HvTooltip>
            <HvTooltip title={<HvTypography>{"Feedback"}</HvTypography>}>
              <HvButton
                startIcon={<EditNote />}
                variant="secondaryGhost"
                onClick={() => {
                  setLabId(lab?.id);
                  setFeedbackDrawarOpen(!openFeedbackDrawer);
                }}
              ></HvButton>
            </HvTooltip>
          </HvBox>
        );
    }
  };

  function convertTo12HourFormat(timestamp) {
    const date = new Date(timestamp);

    // Extracting date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    // Extracting time components
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";

    // Converting hours to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be converted to 12

    // Formatting into a single string
    const formattedDateTime = `${month}/${day}/${year}`;

    return formattedDateTime;
  }

  const returnSubHeader = (tagDescription) => {
    switch (tagDescription) {
      case ON_DEMAND_LAB:
        return (
          <HvTag
            label={tagDescription}
            color={"#B6DAD0"}
            className={classes.tagLabel}
          ></HvTag>
        );

      case LAB_AS_A_SERVICE:
        return (
          <HvTag label={tagDescription} className={classes.tagLabel}></HvTag>
        );

      case PREP_LAB_ONDEMAND:
        return (
          <HvTag label={tagDescription} className={classes.tagLabel}></HvTag>
        );

      case CO_CREATION_LAB:
        return (
          <HvTag
            label={tagDescription}
            color="#D7E6CF"
            className={classes.tagLabel}
          ></HvTag>
        );
      default:
        return (
          <HvTag
            label={"On Demand"}
            color={"#B6DAD0"}
            className={classes.tagLabel}
          ></HvTag>
        );
    }
  };

  const handleFavoriteToggle = (labId, is_Favorite, favorite_id) => {
    const action = is_Favorite
      ? labServiceObj.deleteFavoriteLab(favorite_id)
      : labServiceObj.addFavoriteLab({ user: user?.id, lab: labId });

    action.then(() => {
      setIsFavorite((prev) => {
        props.markFavorite(!prev);
        return !prev; // Toggle the state
      });
    });
  };
  const isWithinOneMonth = (dateString) => {
    const oneMonthAgo = moment().subtract(1, "months");
    const dateToCheck = moment(dateString);
    return dateToCheck.isAfter(oneMonthAgo);
  };

  function formatList(arr) {
    if (arr.length === 0) return "";

    if (arr.length === 1) return arr[0];

    return arr.slice(0, -1).join(", ") + " and " + arr[arr.length - 1];
  }

  return (
    <>
      <HvGrid container>
        {labsData.map((lab, index) => (
          <Fragment key={index}>
            {openFeedbackDrawer ? (
              <FeedbackDrawer
                labId={lab?.schedule_from ? lab?.id : labId}
                openDrawer={openFeedbackDrawer}
                handleDrawerState={handleFeedbacKState}
              />
            ) : (
              <></>
            )}
            <HvGrid
              item
              rowSpacing={1}
              columnSpacing={1}
              xs={4}
              sm={12}
              md={4}
              xl={2}
              lg={3}
            >
              {
                <HvCard className={classes.card}>
                  <HvTooltip title={<HvTypography>{lab.name}</HvTypography>}>
                    <div style={{ display: "flex", paddingLeft: "12px" }}>
                      <HvCardHeader
                        title={lab.name}
                        subheader={returnSubHeader(lab?.tag_description)}
                        className={classes.hvCardHeader}
                      />

                      {((lab.lab_created_date
                        ? isWithinOneMonth(lab.lab_created_date)
                        : isWithinOneMonth(lab.created_date)) && (
                        <div style={{ display: "flex", paddingTop: "8px" }}>
                          <HvTag color="negative_20" label="New!" />
                        </div>
                      )) ||
                        ((lab.lab_modified_date
                          ? isWithinOneMonth(lab.lab_modified_date)
                          : isWithinOneMonth(lab.modified_date)) && (
                          <div style={{ display: "flex", paddingTop: "8px" }}>
                            <HvTag color="neutral_20" label="Updated!" />
                          </div>
                        ))}
                      <HvToggleButton
                        aria-label="Favorite"
                        notSelectedIcon={
                          lab?.favorite?.is_favorite ? (
                            <FavoriteSelected
                              className={classes.favoirteColor}
                              size="S"
                              title="Remove From Favorite"
                            />
                          ) : (
                            <Favorite
                              color={["secondary"]}
                              title="Mark As a Favorite"
                              size="S"
                            />
                          )
                        }
                        selectedIcon={
                          lab?.favorite?.is_favorite ? (
                            <FavoriteSelected
                              className={classes.favoirteColor}
                              size="S"
                              title="Remove From Favorite"
                            />
                          ) : (
                            <Favorite
                              color={["secondary"]}
                              title="Mark As a Favorite"
                              size="S"
                            />
                          )
                        }
                        onClick={() => {
                          handleFavoriteToggle(
                            lab?.lab ? lab?.lab : lab?.id,
                            lab?.favorite?.is_favorite,
                            lab?.favorite?.favorite_id
                          );
                        }}
                      />
                    </div>
                  </HvTooltip>

                  <CardContent
                    lab={lab}
                    runLab={runLab}
                    showAllLabs={props.showAllLabs}
                  />
                  {selectedTabIndex === 0 ? (
                    <>
                      {lab?.maintenance === true ? (
                        <HvActionBar className={classes.hvActionBar}>
                          <HvTypography variant="selectedNavText">
                            This lab is under maintenance till{" "}
                            {convertTo12HourFormat(lab?.maintenance_end)}
                          </HvTypography>
                        </HvActionBar>
                      ) : (
                        <HvActionBar className={classes.hvActionBar}>
                          {renderSecondaryButton(lab)}
                          <div style={{ flex: 1 }} />
                          {renderLabButton(lab)}
                        </HvActionBar>
                      )}
                    </>
                  ) : (
                    <>
                      <HvActionBar>
                        <HvBox
                          style={{ display: "flex" }}
                          alignItems={"center"}
                        >
                          {lab?.schedule_from ? (
                            <>
                              <HvTypography
                                style={{
                                  fontSize: "13px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {labId}Scheduled:{" "}
                                {convertTo12HourFormatSchedule(
                                  lab?.schedule_from
                                )}{" "}
                                <br />{" "}
                                {convertTo12HourFormatSchedule(
                                  lab?.schedule_from,
                                  true
                                )}
                                -{" "}
                                {convertTo12HourFormatSchedule(
                                  lab?.schedule_to,
                                  true
                                )}{" "}
                                <div style={{ flex: 1 }} />
                              </HvTypography>
                              <HvTooltip title="Cancel Booking">
                                <HvButton
                                  startIcon={<CloseXS />}
                                  variant="primaryGhost"
                                  onClick={() => {
                                    setSelectedSlot(
                                      convertTo12HourFormatSchedule(
                                        lab?.schedule_from
                                      ) +
                                        " - " +
                                        convertTo12HourFormatSchedule(
                                          lab?.schedule_from,
                                          true
                                        ) +
                                        " - " +
                                        convertTo12HourFormatSchedule(
                                          lab?.schedule_to,
                                          true
                                        )
                                    );
                                    setCancelDialog(!cancelDialog);
                                    setSlotId(lab.slotID);
                                  }}
                                  style={{ paddingLeft: "10px" }}
                                ></HvButton>
                              </HvTooltip>
                            </>
                          ) : (
                            <HvButton
                              variant="primary"
                              onClick={() =>
                                configureLabAndRedirect(
                                  lab.lab,
                                  lab.name,
                                  lab.url,
                                  lab.session,
                                  lab.id,
                                  lab.instruction,
                                  lab?.enviroment_id,
                                  lab?.lab_type,
                                  lab?.max_time,
                                  lab?.used_time,
                                  lab?.extended_count,
                                  lab?.url_end_time,
                                  lab?.extended_minutes,
                                  lab?.co_creation_lab,
                                  lab?.session_end_time,
                                  lab?.provider
                                )
                              }
                            >
                              <PlayVideoScreen
                                color={!runLab ? "#FBFCFC" : ""}
                              />
                              View Lab
                            </HvButton>
                          )}
                          <HvBox
                            tyle={{ display: "flex" }}
                            alignItems={"center"}
                          >
                            <HvTooltip
                              title={<HvTypography>{"Feedback"}</HvTypography>}
                            >
                              <HvButton
                                startIcon={<EditNote />}
                                variant="primaryGhost"
                                onClick={() => {
                                  setLabId(lab?.lab);
                                  setFeedbackDrawarOpen(!openFeedbackDrawer);
                                }}
                              ></HvButton>
                            </HvTooltip>
                          </HvBox>
                        </HvBox>

                        <></>
                      </HvActionBar>
                    </>
                  )}
                </HvCard>
              }
              {openEmailPopup ? (
                <HvDialog
                  fullscreen={true}
                  open={openEmailPopup}
                  onClose={() => {
                    setOpenEmailPopup(false);
                    localStorage.removeItem("LabUrl");
                    setEmailNote("");
                  }}
                  aria-label="Send Email"
                  className={classes.hvDialog}
                >
                  <HvDialogTitle>Send Email </HvDialogTitle>
                  <HvDialogContent>
                    <form
                      id="dialog-form"
                      onSubmit={(evt) => {
                        evt.preventDefault();
                        localStorage.removeItem("LabUrl");
                      }}
                    >
                      <HvInput
                        name="To"
                        label="To"
                        placeholder="Enter recipient(s) separated by commas"
                        value={toEmails}
                        onChange={handleToChange}
                        disableClear
                      />
                      <br />
                      <div className={classes.labelContainer}>
                        <HvLabel label="Subject" className={classes.label} />
                      </div>
                      <div className={classes.inputContainer}>
                        <HvTypography noWrap>HALO Lab: </HvTypography>
                        <HvInput
                          name="Subject"
                          placeholder="Enter Subject"
                          rows={3}
                          defaultValue={emailLabName}
                          value={emailLabName}
                          onChange={(e) => {
                            setEmailLabName(e.target.value);
                          }}
                          disableClear
                        />
                      </div>
                      <br />
                      <HvTextArea
                        name="Body"
                        label="Sender's Note"
                        placeholder="Type here"
                        rows={6}
                        value={emailNote}
                        onChange={(e) => {
                          setEmailNote(e.target.value);
                        }}
                        middleCountLabel="of"
                        maxCharQuantity={100}
                        blockMax={true}
                        disableClear
                      />
                    </form>
                  </HvDialogContent>
                  <HvDialogActions>
                    <HvButton
                      type="submit"
                      form="dialog-form"
                      variant="secondaryGhost"
                      onClick={() => {
                        sendEmail(lab);
                      }}
                    >
                      Send
                    </HvButton>
                    <HvButton
                      id="cancel"
                      variant="secondaryGhost"
                      onClick={() => {
                        setOpenEmailPopup(false);
                        localStorage.removeItem("LabUrl");
                        setEmailNote("");
                      }}
                    >
                      Cancel
                    </HvButton>
                  </HvDialogActions>
                </HvDialog>
              ) : (
                <></>
              )}
              {openConfirmationPopup ? (
                <HvDialog
                  open={openConfirmationPopup}
                  onClose={() => {
                    setOpenConfirmationPopup(false);
                    localStorage.removeItem("LabUrl");
                  }}
                  aria-label="Send Email"
                  className={classes.hvDialog}
                >
                  <HvDialogTitle>Confirm</HvDialogTitle>
                  <HvDialogContent indentContent>
                    <HvTypography>
                      Please choose an option to share the lab via email. If
                      Outlook is installed on your device, you can send the lab
                      link using Outlook (make sure your browser’s mailTo
                      protocols are enabled). Otherwise, you can share it using
                      the email screen.{" "}
                    </HvTypography>
                  </HvDialogContent>
                  <HvDialogActions className={classes.hvDialog}>
                    <HvTypography variant="selectedNavText">
                      {labsData.find((item) => item.id === labId)
                        ?.group_permissions?.length > 0 ? (
                        <>
                          This lab can be accessed only by{" "}
                          {formatList(
                            labsData.find((item) => item.id === labId)
                              ?.group_permissions
                          )}
                          .
                        </>
                      ) : (
                        <></>
                      )}{" "}
                    </HvTypography>
                    <HvButton
                      type="submit"
                      form="dialog-form"
                      variant="secondaryGhost"
                      onClick={() => {
                        setOpenConfirmationPopup(!openConfirmationPopup);
                        window.location.href = `mailto:${""}?subject=${encodeURIComponent(
                          emailLabName
                        )}&body=${dataUri}`;
                      }}
                    >
                      Open Outlook
                    </HvButton>
                    <HvButton
                      id="submit"
                      variant="secondaryGhost"
                      onClick={() => {
                        setOpenConfirmationPopup(!openConfirmationPopup);
                        setOpenEmailPopup(true);
                      }}
                    >
                      Use Email Screen
                    </HvButton>
                  </HvDialogActions>
                </HvDialog>
              ) : (
                <></>
              )}

              {openAgreementDialog && lab?.lab_type === PREP_LAB ? (
                <>
                  <AgreementDialog
                    openAgreementDialog={(value) => {
                      setOpenAgreementDialog(value);
                    }}
                    launchOrScheduleLab={(value) => {
                      launchPrepLab(lab?.id, lab?.name, value, lab?.lab_type);
                    }}
                  />
                </>
              ) : (
                <></>
              )}
            </HvGrid>
          </Fragment>
        ))}
        {cancelDialog ? (
          <>
            <HvDialog
              disableBackdropClick
              open={cancelDialog}
              onClose={() => setCancelDialog(false)}
              aria-labelledby="hv-dialog-title"
              aria-describedby="hv-dialog-description"
              id="test"
            >
              <HvDialogTitle id="hv-dialog-title" variant="warning">
                Cancel Slot
              </HvDialogTitle>
              <HvDialogContent id="hv-dialog-description" indentContent>
                Do you want to cancel this slot {selectedSlot}..?
              </HvDialogContent>
              <HvDialogActions>
                <HvButton
                  id="apply"
                  category="ghost"
                  onClick={() => cancelSlot(slotId)}
                >
                  Cancel Slot
                </HvButton>
              </HvDialogActions>
            </HvDialog>
          </>
        ) : (
          <></>
        )}
      </HvGrid>
    </>
  );
};
LabsCardComp.propTypes = {
  history: PropTypes.any,
};
export default withRouter(LabsCardComp);
