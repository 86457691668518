import React from "react";
import { Switch, Route } from "react-router-dom";
import Login from "containers/login/Login";
import { LoginCallback } from "@okta/okta-react";
import SsoLogin from "components/SsoLogin";
import EmailAuthenticate from "components/EmailAuthenticate";
import EVideos from "containers/videos/EVideos";
import Tools from "containers/tools/Tools";
import Documents from "containers/documents/Documents";
import CoCreationForm from "containers/coCreation/Form";
import AdminRoutes from "./AdminRoutes";
import PageNotFound from "components/PageNotFound";
import ScPortal from "components/newScPortal/ScPortal";
import SignOff from "components/SignOff";
import ResetPassword from "containers/login/ResetPassword";
import ForgotPassword from "containers/login/ForgotPassword";
import Register from "containers/login/Register";
import { FaqList } from "containers/admin/masters";
import PrivateRoute from "components/PrivateRoute";
import ROLES from "constants/roles";
import LabFeedbackForm from "containers/admin/masters/feedback/LabFeedbackForm";
import Report from "containers/reports/Report";
import LabLandingPage from "containers/newHome/LabLandingPage";
import LabDetailView from "containers/newHome/LabDetailView";
import LabScheduler from "containers/newHome/LabScheduler";
import ISVRequestCardLayout from "containers/admin/masters/ISV-Request/ISVRequestCardLayout";
import LabContentInTab from "components/newScPortal/LabContentInTab";
import UserGuide from "components/UserGuide";
import FAQs from "components/faq/FAQs";
import ProfilePage from "components/gamification/ProfilePage";

export default function Routes() {
  return (
    <Switch>
      {/* <Route  exact path="/" component={Home} /> */}
      <Route exact path="/" component={Login} />
      <Route path="/login/callback" component={() => <LoginCallback />} />
      <Route path="/login" component={Login} />
      <Route path="/sso-login" component={SsoLogin} />
      <Route path="/email-login" component={Login} />
      <Route path="/email/authenticate" component={EmailAuthenticate} />
      <Route path="/registration" component={Register} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/labs" component={LabLandingPage} />
      <Route path="/labscheduler" component={LabScheduler} />
      <Route path="/labdetailview" component={LabDetailView} />
      <Route path="/videos" component={EVideos} />
      <Route path="/tools" component={Tools} />
      <Route path="/documents" component={Documents} />
      <Route path="/co-creation" component={CoCreationForm} />
      <Route path="/isv-request" component={ISVRequestCardLayout} />
      <Route path="/scportal" component={ScPortal} />
      <Route path="/admin" component={AdminRoutes} />
      <Route path="/logout" component={SignOff} />
      <Route path="/faq" component={FaqList} />
      <Route path="/instructionInTab" component={LabContentInTab} />
      <Route path="/UserGuide" component={UserGuide} />
      <Route path="/FAQs" component={FAQs} />
      <Route path="/ProfilePage" component={ProfilePage} />

      <PrivateRoute
        exact
        path="/labdetailview/:labId"
        requiredRoles={[ROLES.System_User, ROLES.User]}
        component={LabDetailView}
      />
      <PrivateRoute
        exact
        path="/report"
        requiredRoles={[ROLES.System_User]}
        component={Report}
      />
      <PrivateRoute
        exact
        path="/feedback/:labId"
        requiredRoles={[ROLES.System_User]}
        component={LabFeedbackForm}
      />
      <Route
        exact
        path="/403"
        component={() => (
          <PageNotFound
            title="Error 403"
            description="You are not authorised to this module"
          />
        )}
      />
      <Route
        component={() => (
          <PageNotFound title="Error 404" description="Page not found error" />
        )}
      />
    </Switch>
  );
}
