import {
  HvBox,
  HvContainer,
  HvGrid,
  HvSection,
  HvTypography,
} from "@hitachivantara/uikit-react-core";

const Achievements = () => {
  const badgeArr = [
    "Badge 1",
    "Badge 2",
    "Badge 3",
    "Badge 4",
    "Badge 5",
    "Badge 6",
    "Badge 7",
    "Badge 8",
    "Badge 9",
    "Badge 10",
    "Badge 11",
    "Badge 12",
  ];
  return (
    <HvSection title="Achievements">
      <HvGrid container spacing={2} style={{ paddingTop: "10px" }}>
        {badgeArr.map((badge, index) => (
          <HvGrid item xs={3} key={index}>
            <HvTypography>{badge}</HvTypography>
          </HvGrid>
        ))}
      </HvGrid>
    </HvSection>
  );
};

export default Achievements;
