import { css } from "@emotion/css";

// export const useStyles = makeStyles(() => ({
//   root: {
//     //   position: 'absolute',
//     //   backgroundSize: 'contain !important',
//   },
//   responsive: {
//     width: "65%",
//     height: "auto",
//     "@media(min-width:320px) and (max-width: 767px)": {
//       display: "none",
//     },
//   },
//   formContainer: {
//     padding: "10% 32px 0 54px",
//     textAlign: "center",
//     width: "100%",
//     background: "#FBFCFC 0% 0% no-repeat padding-box",
//     opacity: "1",
//   },
//   title: {
//     textAlign: "center",
//   },
//   link: {
//     marginTop: "46px",
//     font: "normal normal 600 14px/24px Open Sans",
//     letterSpacing: "0px",
//     opacity: 1,
//     cursor: 'pointer'
//   },
//   or: {
//     marginTop: "22px",
//     font: "normal normal 600 14px/24px Open Sans",
//     letterSpacing: "0px",
//     color: "#6C6B6B",
//     opacity: 1,
//     cursor: 'pointer'
//   },
//   newUser: {
//     marginTop: "46px",
//     textAlign: "left",
//     font: "normal normal normal 14px/24px Open Sans",
//     letterSpacing: "0px",
//     color: "#414141",
//     opacity: 1,
//   },
// label: {
//   marginTop: "18px",
//   "& .HvLabel-root": {
//     textAlign: "left",
//     font: "normal normal normal 14px/20px Open Sans",
//     letterSpacing: "0px",
//     color: "#414141",
//     opacity: 1,
//   },
//   textAlign: "left",
//   font: "normal normal normal 14px/20px Open Sans",
//   letterSpacing: "0px",
//   color: "#414141",
//   opacity: 1,
//   display: "block"
// },
// input: {
//   background: '#FBFCFC 0% 0% no-repeat padding-box',
//   border: '1px solid #6C6B6B',
//   borderRadius: '2px',
//   opacity: '1',
//   width: '100%',
//   height: '32px',
//   display: 'block',
//   padding: '6px 5px 5px',
//   overflow: 'hidden',
//   textOverflow: 'ellipsis',
//   font: ' normal normal normal 14px/24px Open Sans',
//   letterSpacing: '0px',
//   color: '#414141',
// },
// loginButtonContainer: {
//   marginTop: "48px",
//   display: "flex",
//   justifyContent: "space-between",
//   paddingLeft: "10px",
// },
// loginButton: {
//   width: "128px",
//   alignSelf: "flex-end",
// },
// errormsg: {
//   textAlign: "left"
// },
// underline: {
//   "&:before": {
//     borderBottom: "2px solid green",
//   },
//   "&:hover:not($disabled):not($focused):not($error):before": {
//     borderBottom: "2px solid blue",
//   },
//   "&:after": {
//     borderBottom: "3px solid purple",
//   },
// },

export const classes = {
  mainContainer: css({
    width: "100vw",
    height: "100vh",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    background: "rgb(249, 249, 249)",
  }),

  newHomePage: css({
    width: "100%",
    minHeight: "120vh", // Default for medium screens
    backgroundImage: 'url("/assets/images/NewHomePage.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    "&.HvBox-root": {
      display: "flex",
      paddingTop: "20px",
      paddingLeft: "200px",
      paddingRight: "0px",
      paddingBottom: "10px",
      justifyContent: "left",
      alignItems: "flex-start",
    },
  
    // Media Queries
    "@media (min-width: 1600px)": {
      minHeight: "80vh", // For larger screens
    },
    "@media (max-width: 1024px)": {
      minHeight: "70vh", // For smaller screens
    }
  }),
  
  exploreLab: css({
    "&.HvButton-root": {
      borderRadius: "var(--uikit-radii-full)",
      color: "var(--uikit-colors-cat25)",
      backgroundColor: "var(--uikit-colors-atmo1)",
      transition: "transform 0.3s ease-in-out", // Added transition property
      "&:hover": {
        transform: "scale(1.05)", // Scale up on hover
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
      },
    },
  }),
  eGuide: css({
    "&.HvButton-root": {
      borderRadius: "var(--uikit-radii-full)",
      color: "var(--uikit-colors-atmo1)",
      transition: "transform 0.3s ease-in-out", // Added transition property
      "&:hover": {
        transform: "scale(1.05)", // Scale up on hover
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2)",
      },
    },
  }),

  radioGroupStatistics: css({
    "&.fade-enter": {
      opacity: 0,
      transition: "opacity 0.5s ease-in",
    },
    "&.fade-enter-active": {
      opacity: 1,
    },
    "&.fade-exit": {
      opacity: 1,
      transition: "opacity 0.5s ease-out",
    },
    "&.fade-exit-active": {
      opacity: 0,
    },
  }),

  footerImage: css({
    width: "100%",
    display: "block",
    minHeight: "30vh",
    backgroundImage: 'url("/assets/images/originalFooter_1.png")',
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  }),
  footerContainer: css({
    "&.HvContainer-root": {
      paddingLeft: "0 !important",
      paddingRight: "0 !important",
    },
  }),
  stackSpacing : css({
    "&.HvStack-root": {
     
    },
  })
};
