import {
  HvBox,
  HvButton,
  HvContainer,
  HvSection,
  HvTypography,
} from "@hitachivantara/uikit-react-core";
import { Calendar, Heart } from "@hitachivantara/uikit-react-icons";

const LabInteractions = () => {
  return (
    <HvSection
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <HvButton
        startIcon={<Calendar />}
        variant="semantic"
        // style={{
        //   margin: "0px 50px",
        // }}
      >
        Schedule Reminder
      </HvButton>

      <HvButton
        startIcon={<Heart />}
        variant="semantic"
        // style={{
        //   margin: "0px 50px",
        // }}
      >
        Saved Resources
      </HvButton>
    </HvSection>
  );
};

export default LabInteractions;
