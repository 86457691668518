//API Method Constants

//for sharing portal redirection
export const Method_Activate_Lab_Url = 'session/';
export const Method_Running_Lab_Url = 'session/';
export const Method_Scheduled_Lab_Url = 'lab-get-scheduler/';
export const Method_New_Lab_Url = 'lab/';
export const Method_Update = 'lab/{LAB_ID}/';
export const Method_Landing = 'landing';
export const INSTRUCTION_URL = 'instruction/'
export const AVAILABLE_SLOTS = 'lab-available-slots/'
export const SCHUDLE_LAB = 'lab-scheduler/'
export const SEND_MAIL = "mail-lab/"
export const INVOKE_LAB_CLEANUP = "invoke-lab-cleanup/"
export const INVOKE_LAB_CLEANUP_SETUP = "lab-cleanup-setup/"
export const USER_AGREEMENT = "user-agreement/"
export const AGREEMENT = "agreement/"
export const Feedback_URL = 'feedback/'
export const Feedback_Resource_Url = "feedback-resource/"
export const hardware_labs = "hardware-labs/"
export const VM_STATUS = "/session/status/"
export const LAB_EXTEND = "/session/extend/"
export const HARDWARE_LAB_EXTEND = '/lab-scheduler/extend/'
export const LAB_CURRENT_SLOT = "lab-scheduler/current-slot/"
export const LAB_USED_TIME = "session/used-time/"
export const CLEARSIGHT_VALIDATE = "ap/is_session_valid/"
export const RESOURCE_REPORT = "resource-report/"
export const FAVORITE_RESOURCE = "favorite-resource/"
export const FAVORITE_LAB= "favorite-labs/"
export const AWS_CLEANUP = "aws-cleanup/"

//Pagination
export const PAGINATION_SIZE_OPTIONS = [8, 12, 24, 50, 100];
export const REPORT_SIZE_OPTIONS = [10, 20, 25, 50, 100]

//common message
export const LAB_SERVICE_MESSAGE = "This lab runs on real hardware and needs to be scheduled."
export const LAB_ON_DEMAND_MESSAGE = "This lab runs on simulators and does not need to be scheduled. Please note you may have limited functionality."
export const SAAS_LAB_MESSAGE = "This demo logs you into a fictitious customer account on Clear Sight’s cloud-based platform."
export const SENDERS_NOTE = "Sender's Note can not be empty."
export const VM_STATUS_NOTE = "Please stand by while we prepare your lab."
export const VM_STATUS_NOTE_ERROR = "Thanks for your patience! This is taking a little longer than we expected."


export const PREP_LAB = "PREP Lab"
export const ON_DEMAND_LAB = "On Demand"
export const LAB_AS_A_SERVICE = "Lab As A Service"
export const PREP_LAB_ONDEMAND = "PREP On Demand"
export const CO_CREATION_LAB = "CoCreation Lab"
export const SAAS_LAB = "SAAS Lab"

export const CONFIRM_STEPS_MESSAGE = "Please confirm you have saved your changes  to ensure no data is lost."
export const SAAS_LAB_TERMINATE_MESSAGE = "Are you sure you want to terminate the lab?"
export const OTHER_THAN_SAAS_LAB_TERMINATE_MESSAGE = " If you have just launched the lab then please wait for 5 mins and then terminate. Terminate lab will end the lab. You need to run the lab again."